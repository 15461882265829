import React, { useEffect, useContext, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';

import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';

import { JsonDataContext } from 'context/jsonData';
import { getPageById } from 'utils';
import { BtnBase } from 'components/BtnBase';

const NetworkServicesPage = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 348);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	const pageContent = currentPageData && currentPageData.content;

	return (
		currentPageData && pageContent && (
			<section className="section bg_v3_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<div className="section_in">
					{pageContent[0] && pageContent[0].title && (
						<SectionHead
							title={pageContent[0].title}
							titleH1
						/>
					)}
					{pageContent[1] && pageContent[1].title && (
						<h2 className="section_title size_mod col col_4 offset_2 offset_mod">{ReactHtmlParser(pageContent[1].title)}</h2>
					)}
					<div className="product_list row col col_5 offset_1 offset_2_mod">
						{pageContent[2] && pageContent[2].text_block && pageContent[2].text_block.map((item, index) => {
							return (
								<div className="product_item col col_lg_1 col_tab_1 col_mob_3" key={`product_${index}`}>
									{ReactHtmlParser(item.text)}
								</div>
							)
						})}
					</div>
					{pageContent[3] && pageContent[3].title && (
						<h2 className="section_title size_mod col col_4 offset_2 offset_mod">{pageContent[3].title}</h2>
					)}
					{pageContent[3] && pageContent[3].text && (
						<div className="section_descr color_mod col col_5 offset_1">{ReactHtmlParser(pageContent[3].text)}</div>
					)}
					{pageContent[4] && pageContent[4].cta_button && (
						<BtnBase
							buttonSettings={pageContent[4].cta_button}
							offsetMod
						/>
					)}
				</div>
			</section>
		)
	);
};

export default NetworkServicesPage;