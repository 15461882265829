import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { CSSTransition } from 'react-transition-group';
import { ReactComponent as CloseIcon } from 'i/icons/close.svg';
import { AnimationContext } from 'context/animation';
import { TABLET_MEDIA_POINT } from 'utils/constant';
import { nameValidation, emailValidation, fieldValidation, phoneValidation } from 'utils';
import { FormFormik } from 'components/Form';
import APP_DATA from 'utils/jsonAppData';
import PopupClose from "./PopupClose";

// const EMAIL_SUBJECT = 'Feedback from site Avelacom.com (Support)';
// const EMAIL_SUBJECT_WITH_UPDATE = 'Feedback from site Avelacom.com (Support + News)';

const CustomerSupportPopup = ({
	isOpen,
}) => {
	const { windowWidth, showPopupByKey } = useContext(AnimationContext);

	const validate = {
		fullName: name => nameValidation("Name", name),
		email: emailValidation,
		company: () => { return null },
		phone: () => { return null },
		companyLocation: () => { return null },
		help: () => { return null },
		newsSubscribe: () => { return null },
		subjectEmail: () => { return null },
	};

	const initialValues = {
		fullName: '',
		company: '',
		email: '',
		phone: '',
		companyLocation: '',
		help: '',
		newsSubscribe: false,
		subjectEmail: APP_DATA.customerSupportForm.subjectEmail,
	};

	const handleClosePopup = () => {
		showPopupByKey();
	};

	// subjectTheme: 'Feedback from site Avelacom.com (Support)',
	// subjectThemeWithUpdate: 'Feedback from site Avelacom.com (Support + News)'

	return (
		<CSSTransition
			in={isOpen}
			timeout={{
				enter: 500,
				exit: 350,
			}}
			classNames="popup"
			mountOnEnter
			unmountOnExit
		>
			<div className="popup request_mod">
				<div className="overlay" onClick={handleClosePopup} />
				<div className="popup_in request_mod">
					{windowWidth >= TABLET_MEDIA_POINT ? (
						<PopupClose onClickHandler={handleClosePopup} />
					) : null}
					<div className="section_head offset_v6_mod">
						<div className="section_w_title">
							<div className="section_title">Customer Support</div>
							{windowWidth < TABLET_MEDIA_POINT ? (
								<div className="popup_close_btn" onClick={handleClosePopup}>
									<CloseIcon className="popup_close_icon" />
								</div>
							) : null}
						</div>
						<div className="section_descr color_mod offset_l_mod">If you are a current Avelacom customer experiencing an urgent production issue, please contact our Global NOC team. For non-urgent support inquiries please fill out this form</div>
					</div>
					<FormFormik validate={validate} initialValues={initialValues} />
				</div>
			</div>
		</CSSTransition>
	);
};

export default CustomerSupportPopup;

// CustomerSupportPopup.propTypes = {
// 	handleOpenPopup: PropTypes.func.isRequired,
// };
