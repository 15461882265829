import React from 'react';

const ScrollDecor = React.forwardRef(({ onClickHandler }, ref) => {
	return (
		<>
			<div className="scroll_decor desc_mod">
				<div className="scroll_decor_title" onClick={onClickHandler}>
					scroll to see more
				</div>
				<div className="scroll_decor_line">
					<div className="scroll_decor_line_in"></div>
				</div>
				<div className="scroll_decor_circle_w">
					<div className="scroll_decor_circle_in" ref={ref}>
						<div className="scroll_decor_circle"></div>
					</div>
				</div>
			</div>
			<div className="scroll_decor mob_mod" onClick={onClickHandler}>swipe down <br />to see more</div>
		</>
	);
});

export default ScrollDecor;