import React, { useState } from 'react';
import { Decors } from 'components/Decors';
import { SearchForm, SearchResultItem } from 'components/Search';
import { Spinner } from 'components/Spinner';
import { Helmet } from 'react-helmet';
import jsonAppData from 'utils/jsonAppData';

const SearchPage = () => {
	const [searchResult, setSearchResult] = useState(false);
	const [isSearchLoading, setSearchLoading] = useState(false);
	const [isFetchError, setFetchError] = useState(false);
	const [isFormSubmitted, setFormSubmitted] = useState(false);

	return (
		<section className="section offset_top_mod bg_v4_mod content_mod">
			{jsonAppData.searchPage.title && (
				<Helmet>
					<title>{jsonAppData.searchPage.title}</title>
				</Helmet>
			)}
			<Decors />
			<div className="section_in">
				<div className="row col col_5 offset_1 search_form_wrap">
					<h2 className="section_title offset_v3_mod">Search results</h2>
					<SearchForm
						setSearchResult={setSearchResult}
						setSearchLoading={setSearchLoading}
						setFetchError={setFetchError}
						isFormSubmitted={isFormSubmitted}
						setFormSubmitted={setFormSubmitted}
					/>
				</div>
				{!isSearchLoading ? (
					searchResult && searchResult.length ? (
						<div className="row col col_4 offset_2">
							<ul className="search_result_list">
								{searchResult.map(({
									id,
									title,
									url,
									excerpt,
									subtype,
								}) => {
									return (
										<li className="search_result_list_item">
											<SearchResultItem
												key={id}
												url={url}
												title={title}
												excerpt={excerpt}
												subtype={subtype}
											/>
										</li>
									)
								})}
							</ul>
						</div>
					) : (
							searchResult && !searchResult.length && (
								<div className="row col col_5 offset_1">
									<h2 className="section_title size_mod center_mod">No result</h2>
								</div>
							)
						)
				) : (
						!isFetchError && (
							<Spinner />
						)
					)}
			</div>
		</section>
	);

};

export default SearchPage;