import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CheckIcon } from 'i/icons/checked.svg';

const SolutionTableItem = ({
	title,
	icon,
}) => {
	return (
		<div className="solution_table_item">
			{icon && (
				<div className="solution_table_icon">
					<CheckIcon className="icon size_mod" />
				</div>
			)}
			{title && (
				<div className="solution_table_title">{title}</div>
			)}
		</div>
	);
};

export default SolutionTableItem;

SolutionTableItem.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.bool,
};