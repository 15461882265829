import React from 'react';
import PropTypes from 'prop-types';

const MenuTrigger = ({ handleClick }) => {
	return (
		<div className="menu_trigger" onClick={handleClick}>
			<span className="menu_trigger_decor" />
		</div>
	);
};

export default MenuTrigger;

MenuTrigger.propTypes = {
	handleClick: PropTypes.func.isRequired
};