import React from 'react';

import { JOIN_FORM_INPUTS } from './constant';

import { ButtonSubmit } from 'components/Buttons';
import { Alert } from 'components/Alert';

const JoinForm = ({
	errors,
	handleBlur,
	handleChange,
	handleSubmit,
	touched,
	values,
	isSubmitting,
	alert,
	hideAlert
}) => {
	return (
		<form className="join_form" onSubmit={handleSubmit} noValidate>
			<div className="join_form_in">
				{JOIN_FORM_INPUTS.map(({
					type,
					id,
					placeholder,
					name,
					required
				}) => {
					return (
						<dl className="form_cell form_cell_v1_mod v2_mod" key={id}>
							<dt className="form_cell_title form_v1_mod hline_hide_mod">
								<label htmlFor="join_name" />
							</dt>
							<dd className="form_field_wrap form_v1_mod hline_hide_mod">
								<input
									className="form_field default_mod"
									type={type}
									id={id}
									placeholder={placeholder}
									name={name}
									value={values[name]}
									required={required}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								<div className="form_error">{touched[name] && errors[name]}</div>
							</dd>
						</dl>
					)
				})}
			</div>
			<div className="btn_wrap offset_mod submit_mod">
				{alert.visible ? (
					<Alert
						alert={alert}
						hide={hideAlert}
					/>
				) : (
						<ButtonSubmit
							buttonText="Submit"
							isDisabled={isSubmitting}
						/>
					)}
			</div>
		</form>
	);
};

export default JoinForm;