import React, { useRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { Decors, ScrollDecor } from 'components/Decors';
import { SectionBg } from 'components/SectionBg';
import { AnimationContext } from 'context/animation';

import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(SplitText);

const HomePageSectionOne = ({
	sectionData,
	activeId,
	timelinesLength,
	moveToNextSection,
}) => {
	const {
		section_title,
		section_description,
		section_background_image
	} = sectionData;
	const sectionTitleEl = useRef(null);
	const sectionDescrEl = useRef(null);
	const sectionTitleDecorEl = useRef(null);
	const scrollDecorCircleEl = useRef(null);

	const [tlOnloadAnim] = useState(gsap.timeline({
		delay: 1
	}));

	const {
		setHeroAnimDone
	} = useContext(AnimationContext);

	let sectionDescrLinesEl;

	const splitSentenceToWords = () => {
		let titleString = section_title;
		let splittedString = titleString.split(' ');

		const newTitleMarkup = splittedString.map(word => {
			const wordMarkup = `
				<div class="word_wrap">
					<div class="word_w_title">
						<div class="word_title">${word}</div>
					</div>
					<div class="word_src">${word}</div>
				</div>
			`;

			return wordMarkup;
		});

		newTitleMarkup.map(word => {
			sectionTitleEl.current.insertAdjacentHTML('beforebegin', word);
		});

	};

	useEffect(() => {
		if (sectionTitleEl.current) {
			splitSentenceToWords();

			let sectionTitleWordW = document.querySelectorAll('.word_w_title');
			let sectionTitleWord = document.querySelectorAll('.word_title');
			sectionDescrLinesEl = new SplitText(sectionDescrEl.current.querySelector('p'), { type: "lines", linesClass: "lines_parent" });

			tlOnloadAnim
				.addLabel('anim')
				.fromTo(sectionTitleWordW, {
					xPercent: -100
				}, {
					xPercent: 0,
					duration: 1.4,
					ease: "power1.out"
				})
				.fromTo(sectionTitleWord, {
					xPercent: 100
				}, {
					xPercent: 0,
					duration: 1.4,
					ease: "power1.out"
				}, 'anim')
				.fromTo(sectionTitleDecorEl.current, {
					xPercent: -100
				}, {
					xPercent: 0,
					duration: 1.4,
					ease: "power1.out"
				}, 'anim')
				.fromTo(sectionDescrLinesEl.lines, {
					xPercent: -100
				}, {
					xPercent: 0,
					duration: 1,
					stagger: .4,
					ease: "power3.out",
					onComplete: ()=> {
						if (sectionDescrEl.current) {
							sectionDescrEl.current.classList.add('active_decor_mod')
						}
					}
				}, 'anim')
				.fromTo('.scroll_item_in', {
					xPercent: -100
				}, {
					xPercent: 0,
					duration: 1.2,
					stagger: .2,
					ease: "power3.out"
				}, 'anim+=1.1')
				.fromTo('.scroll_decor_line_in', {
					yPercent: -100
				}, {
					yPercent: 0,
					duration: 1.4,
					ease: "power3.out"
				}, 'anim+=1.3')
				.fromTo('.scroll_decor_title', {
					yPercent: -50,
					opacity: 0
				}, {
					yPercent: 0,
					opacity: 1,
					duration: 1.4,
					ease: "power3.out"
				}, 'anim+=1.3')
				.from(scrollDecorCircleEl.current, {
					opacity: 0,
					onComplete: () => {
						if (scrollDecorCircleEl.current) {
							scrollDecorCircleEl.current.classList.add('active_mod');
						}
						setHeroAnimDone(true);
					}
				}, 'anim+=1.8')
		}
	}, []);

	useEffect(() => {
		if (scrollDecorCircleEl.current) {
			const yPercentVal = activeId * 100 / timelinesLength;

			gsap.to(scrollDecorCircleEl.current, {
				yPercent: yPercentVal
			});
		}
	}, [activeId]);

	return (
		<>
			<ScrollDecor
				ref={scrollDecorCircleEl}
				onClickHandler={moveToNextSection}
			/>
			<ul className="scroll_list">
				{new Array(5).fill(null).map((item, index) => {
					return (
						<li className={`scroll_item ${index === activeId ? 'active_mod' : ''}`} key={index}>
							<div className="scroll_item_in"></div>
						</li>
					)
				})}
			</ul>
			<section className="section index_mod center_content_mod hero_mod heroSection">
				<SectionBg
					url={section_background_image.url}
					alt="section background"
				/>
				<Decors />
				<div className="section_in">
					<div className="section_head center_mod col col_4 offset_1">
						<h1 className="section_title large_mod color_mod decor_mod">
							<span className="decor_box" ref={sectionTitleEl}>
								<div className="title_decor_w">
									<span className="title_decor" ref={sectionTitleDecorEl} />
								</div>
							</span>
						</h1>
						<div className="section_descr color_mod offset_hero_mod size_v2_mod hero_anim_mod" dangerouslySetInnerHTML={{ __html: section_description }} ref={sectionDescrEl} />
					</div>
				</div>
			</section>
		</>
	);
};

export default HomePageSectionOne;

HomePageSectionOne.propTypes = {
	sectionData: PropTypes.object.isRequired
};