import React, { useEffect, useContext, useRef } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { gsap } from "gsap";
import { Transition, TransitionGroup } from 'react-transition-group';

import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import * as PAGES from './pages';
import { JsonDataContext } from 'context/jsonData';
import { AnimationContext } from 'context/animation';
import { Preloader } from 'components/Preloader';
import { OVERLAY_PAGES } from "utils/constant";
import {
	SearchPopup,
	ThanksGetTouchPopup,
	CustomerSupportPopup,
	SalesInquiryPopup,
	JoinPopup,
	ThanksSigningPopup,
	RequestPopup,
} from 'components/Popup';
import { RedirectRoute } from 'components/RedirectRoute';
import {getContentLinks} from "./utils";
import { useHistory } from 'react-router-dom';

function App() {
	const {
		setIsHeaderOverlay,
		setPageScrollTop,
		headerOverlayMod,
		setHeaderOverlayMod,
	} = useContext(JsonDataContext);
	const sectionWrapEl = useRef(null);
	const { pagesData } = useContext(JsonDataContext);
	const { isPreloaderAnimDone, isPreloaderProgressAnimDone, appPopups, setPrevLocation } = useContext(AnimationContext);
	const location = useLocation();
	const history = useHistory();

	const onResize = () => {
		let innerHeight = window.innerHeight;
		let calcRemValue = window.outerWidth * .5625 > innerHeight ? innerHeight / 1080 * 10 : window.outerWidth / 1920 * 10;

		document.documentElement.style.setProperty('--rem', calcRemValue + 'px');
	};

	useEffect(() => {
		setTimeout(function () {
			for (let i = 0; i < OVERLAY_PAGES.length; i++) {
				if ((location.pathname.indexOf(OVERLAY_PAGES[i].url)) == -1) {
					setIsHeaderOverlay(false);
				} else {
					setIsHeaderOverlay(true);
					setHeaderOverlayMod(OVERLAY_PAGES[i].overlayMod);
					break;
				}
			}
		}, 100)

	}, [location, headerOverlayMod]);

	const onEnter = node => {
		gsap.fromTo(
			node,
			{
				opacity: 0
			},
			{
				opacity: 1,
				ease: "power3.InOut",
				duration: .6,
				onComplete: () => {
					setPrevLocation(location.pathname);
				}
			}
		);
	};

	const onExit = node => {
		sectionWrapEl.current.removeEventListener('scroll', onScroll);

		gsap.fromTo(
			node,
			{
				opacity: 1,
			},
			{
				opacity: 0,
				ease: "power3.InOut",
				duration: .6,
				
			}
		);
	};

	const onEntered = () => {
		setTimeout(() => {
			sectionWrapEl.current = document.querySelector('.section_wrap');
			sectionWrapEl.current.addEventListener('scroll', onScroll);
		}, 200);
	};

	useEffect(() => {
		onResize();
		window.addEventListener('resize', onResize);
	}, []);

	const onScroll = () => {
		if (sectionWrapEl.current != null) {
			setPageScrollTop(sectionWrapEl.current.scrollTop);
		}
	};

	useEffect(() => {
		setTimeout(() => {
			if (typeof (sectionWrapEl.current) !== 'undefined' && sectionWrapEl.current != null) {
				sectionWrapEl.current.addEventListener('scroll', onScroll);
			}
		}, 1200);
	}, [sectionWrapEl]);

	useEffect(() => {
		if (pagesData) {
			setTimeout(()=> {
				getContentLinks(history);
			}, 100)
		}
	}, [pagesData, location]);

	return (
		<>
			{(pagesData && isPreloaderAnimDone) ? (
				<div className="wrapper">
					<Header />
					<TransitionGroup>
						<Transition
							key={location.pathname}
							timeout={1200}
							onExit={onExit}
							onEntering={onEnter}
							onEntered={onEntered}
							mountOnEnter
							unmountOnExit
						>
							<div className="section_wrap" ref={sectionWrapEl}>
								<Switch location={location}>
									<Route component={PAGES.HomePage} path="/" exact />
									<Route component={PAGES.ContactPage} path="/contact" />
									<Route component={PAGES.EventsPage} path="/events" />
									{/* <Route component={PAGES.NewsPage} path="/news" /> */}
									<Route
										path="/news"
										render={({ match: { url } }) => (
											<>
												<Route component={PAGES.NewsPage} path={`${url}/`} exact />
												<Route component={PAGES.NewsSinglePage} path={`${url}/:slug`} />
											</>
										)}
									/>
									<Route component={PAGES.InTheNewsPage} path="/in-the-news" />
									<Route component={PAGES.AwardsPage} path="/awards" />
									<Route component={PAGES.AboutUsPage} path="/about-us" />
									<Route component={PAGES.NetworkServicesPage} path="/services/network-services" />
									<Route component={PAGES.BusinessInternetPage} path="/services/business-internet" />
									<Route component={PAGES.DataCenterPage} path="/services/data-center-facilities" />
									<Route component={PAGES.ConnectingCloudsPage} path="/services/connecting-clouds" />
									<Route
										path="/solutions"
										render={({ match: { url } }) => (
											<>
												<RedirectRoute path={`${url}/`} exact />
												<Route component={PAGES.Solution6} path={`${url}/comparison`} />
												<Route component={PAGES.SolutionEnterpise} path={`${url}/global-enterprises`} />
												<Route component={PAGES.SolutionOnline} path={`${url}/media-and-gaming`} />
												<Route component={PAGES.SolutionWholesale} path={`${url}/wholesale`} />
												<Route
													path={`${url}/financial-markets`}
													render={({ match: { url: subUrl } }) => (
														<>
															<Route component={PAGES.Solution1} path={`${subUrl}/`} exact />
															<Route component={PAGES.Solution2} path={`${subUrl}/low-latency-connectivity`} />
															<Route component={PAGES.Solution3} path={`${subUrl}/colocation`} />
															<Route component={PAGES.Solution4} path={`${subUrl}/market-data`} />
															<Route component={PAGES.Solution5} path={`${subUrl}/managed-hosting`} />
															<Route component={PAGES.Solution7} path={`${subUrl}/financial-cloud`} />
															<Route component={PAGES.CryptoTradingPage} path={`${subUrl}/crypto-trading`} />
															<Route component={PAGES.Solution8} path={`${subUrl}/emerging-markets`} />
														</>
													)}
												/>
											</>
										)}
									/>
									<Route
										path="/connectivity-map"
										render={({ match: { url } }) => (
											<>
												<Route component={PAGES.GlobalService} path={`${url}/`} exact />
												<Route component={PAGES.CryptoMarkets} path={`${url}/trading-venues`} />
												<Route component={PAGES.TradingVenues} path={`${url}/crypto-markets`} />
											</>
										)}
									/>
									<Route component={PAGES.GlobalMarkets} path="/quick-stats" />
									<Route component={PAGES.GlobalLocations} path="/pop-locations" />
									<Route component={PAGES.SearchPage} path="/search" />
									<Route component={PAGES.NotFound} />
								</Switch>
							</div>
						</Transition>
					</TransitionGroup>
					<Footer />
					<SearchPopup isOpen={appPopups.search.isOpen} />
					<ThanksGetTouchPopup isOpen={appPopups.getInTouch.isOpen} />
					<CustomerSupportPopup isOpen={appPopups.customerSupport.isOpen} />
					<SalesInquiryPopup isOpen={appPopups.salesInquiry.isOpen} />
					<JoinPopup isOpen={appPopups.join.isOpen} />
					<ThanksSigningPopup isOpen={appPopups.signingUp.isOpen} />
					<RequestPopup isOpen={appPopups.request.isOpen} />
				</div>
			) : null}
			{!isPreloaderProgressAnimDone && <Preloader />}
		</>
	);
}

export default App;


// points = [
// 	{
// 		country: "Netherlands",
// 		id: 23,
// 		region: 10,
// 		cities: [
// 			{
// 				city: 'Amsterdam',
// 				id: 20,
// 			}
// 		],
// 	},
// 	{

// 	},
// ];