import React, { useState, useEffect, useContext } from 'react';

import { Decors } from 'components/Decors';
import { JsonDataContext } from 'context/jsonData';
import ReactHtmlParser from 'react-html-parser';
import { getAllNews } from 'api/data';
import { getNewsBySlug } from 'utils';
import { Spinner } from 'components/Spinner';
import { Alert, useAlert } from 'components/Alert';
import { NewsControl } from 'components/NewsControl';
import { SchemaComponent } from 'components/ShemaComponent';
import { Helmet } from "react-helmet"

import { ReactComponent as Linkedin } from 'i/icons/linkedin.svg';
import { ReactComponent as Twitter } from 'i/icons/twitter.svg';
import APP_DATA from "../../utils/jsonAppData";
import Logo from 'i/logo.svg';

const NewsSinglePage = (props) => {
	const {
		allNews,
		setAllNews,
		setCurrentNewsId
	} = useContext(JsonDataContext);

	const [isNewsLoaded, setNewsLoaded] = useState(false);
	const [isFetchError, setFetchError] = useState(false);
	const [alert, showAlert, hideAlert] = useAlert();
	const [currentNews, setCurrentNews] = useState(null);

	const getCurrentNewsBySlug = allNewsPost => {
		const preparedAllNews = allNews.length ? allNews : allNewsPost;
		const resultNews = getNewsBySlug(preparedAllNews, props.match.params.slug);

		setCurrentNews(resultNews.news);
		setCurrentNewsId(resultNews.newsId);
	};

	const getAllNewsPage = async () => {
		try {
			const resultAllNews = await getAllNews();

			setAllNews(resultAllNews.data);
			setNewsLoaded(true);
			getCurrentNewsBySlug(resultAllNews.data);
		} catch (err) {
			showAlert(err.message, 'danger');
			setNewsLoaded(false);
			setFetchError(true);
		}
	};

	useEffect(() => {
		if (!allNews.length) {
			getAllNewsPage();
		} else {
			setNewsLoaded(true);
			getCurrentNewsBySlug();
		}
	}, []);

	let schemaData = {};

	if (isNewsLoaded && currentNews) {

		const schemaSubtitle = currentNews.acf.subtitle;
		const subtitleTextEl = document.createElement("div");
		subtitleTextEl.innerHTML = schemaSubtitle;
		const descriptionSchema = subtitleTextEl.textContent || subtitleTextEl.innerText || "";

		const schemaText = currentNews.acf.text;
		const schemaTextEl = document.createElement("div");
		schemaTextEl.innerHTML = schemaText;
		const textSchema = schemaTextEl.textContent || schemaTextEl.innerText || "";

		String.prototype.countWords = function () {
			return this.split(/\s+/).length;
		}

		schemaData = {
			"@context": "https://schema.org",
			"@type": "Article/NewsArticle/BlogPost/TechArticle",
			"mainEntityOfPage": {
				"@type": "WebPage",
				"@id": currentNews.link
			},
			"headline": currentNews.title.rendered.substr(0, 110),
			"description": descriptionSchema,
			"image": [currentNews.acf.preview_image],
			"datePublished": currentNews.date,
			"dateModified": currentNews.modified,
			"articleBody": textSchema,
			"wordcount": textSchema.countWords(),
			"author": {
				"@type": "Person/Organisation",
				"name": currentNews.author
			},
			"publisher": {
				"@type": "Organisation",
				"name": "Avelacom",
				"logo": {
					"@type": "ImageObject",
					"url": Logo
				}
			}
		}
	}


	return (
		<>
			<SchemaComponent schemaData={schemaData} />
			<section className="section bg_v1_mod">
				<Decors />
				{isNewsLoaded && currentNews ? (
					<div className="section_in">

						<div className="news_article col col_5 offset_1">
							<div className="section_head offset_v2_mod news_mod">
								{currentNews.title && currentNews.title.rendered && (
									<>
										<Helmet>
											<title>{currentNews.title.rendered}</title>
											<meta property='og:title' content={currentNews.title.rendered} />
											<meta property='og:image' content={currentNews.acf.preview_image} />
											<meta property='og:description' content={currentNews.acf.subtitle.replace(/<[^>]+>/g, '')} />
											<meta property='og:url' content={window.location.href} />
										</Helmet>
										<h1 className="section_title mob_size_mod">{currentNews.title.rendered}</h1>
									</>
								)}
							</div>
							<div className="news_block_wrap">
								<div className="news_img_wrap">
									<img className="news_img" src={currentNews.acf.preview_image} alt={currentNews.title.rendered} />
								</div>
								<div className="news_info row">
									<div className="col col_lg_1 no_offset_in_mod">
										<div className="news_share">
											<div className="news_share_title">share</div>
											<div className="news_share_list">
												<div className="news_share_item">
													<a href={`https://twitter.com/intent/tweet?url=${window.location.href}%2F&text=${currentNews.title.rendered}`} target="_blank" className="news_share_icon twitter-share-button" data-show-count="false">
														<Twitter className="icon icon-twitter size_mod" />
													</a>
												</div>
												<div className="news_share_item">
													<a className="news_share_icon" href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=Custom title &summary=my summary &source=LinkedIn`} target="blank" rel="noopener noreferrer">
														<Linkedin className="icon icon-linkedin size_mod" />
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className="news_img_descr col col_lg_4">
										<div className="section_descr" dangerouslySetInnerHTML={{ __html: currentNews.acf.subtitle }} />
									</div>
								</div>
							</div>
							<div className="news_text" dangerouslySetInnerHTML={{ __html: currentNews.acf.text }} />
						</div>
						<NewsControl />
					</div>
				) : (
					!isFetchError && (
						<Spinner />
					)
				)}
				{alert.visible ? (
					<div className="row col col_5 offset_1">
						<Alert
							alert={alert}
							hide={hideAlert}
						/>
					</div>
				) : null}
			</section>
		</>
	);
};

export default NewsSinglePage;