import React, { useEffect, useRef, useState, useContext } from 'react';
import dateFormat from 'dateformat';

import { SocialList } from 'components/SocialList';
import { TABLET_MEDIA_POINT } from 'utils/constant';
import { AnimationContext } from 'context/animation';
import { SearchPopup } from 'components/Popup';
import { gsap } from "gsap";
import jsonDataContext from 'context/jsonData/jsonDataContext';

const Footer = () => {
	const popupEl = useRef(null);
	const copyrightEl = useRef(null);
	const { windowWidth, showPopupByKey } = useContext(AnimationContext);
	const { appBaseData } = useContext(jsonDataContext);
	const [tlFooter] = useState(gsap.timeline({
		delay: 1.2
	}));

	useEffect(() => {
		if (copyrightEl && windowWidth >= TABLET_MEDIA_POINT) {
			tlFooter
				.addLabel('anim')
				.fromTo(copyrightEl.current, {
					opacity: 0
				}, {
					opacity: 1,
					duration: 5
				})
				.fromTo('.social_item', {
					xPercent: 100
				}, {
					xPercent: 0,
					duration: .3,
					stagger: .2
				}, 'anim-=.2')
		}
	}, []);

	const handleOpenPopup = e => {
		e.preventDefault();

		showPopupByKey('search');
	};

	return (
		<>
			<footer className="footer">
				<div className="section_in footer_mod">
					<div className="row col col_5 offset_1">
						<div className="col col_lg_1 offset_lg_4 col_tab_3 col_mob_3">
							<SocialList handleOpenPopup={handleOpenPopup} />
							{appBaseData && appBaseData.copyright && (
								<div className="footer_copyright" ref={copyrightEl}>{appBaseData.copyright}</div>
							)}
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
