import React, { useEffect, useState } from 'react';

import {
	Map,
	MapCountryList,
	MapCityList,
	MapPointsList,
} from 'components/Map';
import { isPreRendering } from 'utils';

const MapContainer = ({
	mapData,
	mapPoints,
	filterOptions,
	setFilterOptions,
	isFilterReset,
}) => {
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [selectedCity, setSelectedCity] = useState(null);
	const [selectedCityLocation, setSelectedCityLocation] = useState(null);
	const [citiesCountry, setCitiesCountry] = useState(null);
	const [highlightedPoints, setHighlightedPoints] = useState([]);
	const [mapCenter, setMapCenter] = useState({ lat: 45, lng: 9 });

	const selectCitiesByCountryId = () => {
		const newSelectedCities = mapData.cities.filter(({
			country_id,
		}) => {
			if (country_id === selectedCountry.id) return true;
		});

		setCitiesCountry(newSelectedCities);
	};

	const selectLocationByCityId = () => {
		const newSelectedPoints = mapData.points.filter(({
			city,
		}) => {
			if (city.id === selectedCity.id) return true;
		});

		setSelectedCityLocation(newSelectedPoints);
	};

	const goToSelectedCountryLocation = () => {
		const countryId = selectedCountry.id

		const countryPoints = mapData.points.filter(({
			country,
		}) => {
			if (country.id === countryId) return true;
		});

		if (countryPoints.length) {
			const newLat = Math.floor(countryPoints[0].point_coords.lat)
			const newLng = Math.floor(countryPoints[0].point_coords.lng)

			if (countryPoints[0].point_coords.lat !== '') {
				setMapCenter({ lat: newLat, lng: newLng })
			}
		}
	}

	useEffect(() => {
		if (selectedCountry) {
			setFilterOptions({
				...filterOptions,
				country: selectedCountry.id,
				city: 0,
			});

			selectCitiesByCountryId();
			setSelectedCity(null);
			setSelectedCityLocation(null);
			setHighlightedPoints([]);

			goToSelectedCountryLocation();
		}
	}, [selectedCountry]);


	useEffect(() => {
		if (selectedCity) {
			setFilterOptions({
				...filterOptions,
				city: selectedCity.id,
			});

			selectLocationByCityId();
		}
	}, [selectedCity]);

	useEffect(() => {
		if (isFilterReset) {
			setSelectedCountry(null);
			setSelectedCity(null);
			setSelectedCityLocation(null);
			setCitiesCountry(null);
			setHighlightedPoints([]);
		}
	}, [isFilterReset]);

	return (
		<div className="map_container">
			<div className="location_wrap">
				<div className="map_wrap">
					<div className="map_wrap_in">
						{!isPreRendering() ? (
							<Map
								mapPoints={mapPoints}
								highlightedPoints={highlightedPoints}
								mapCenter={mapCenter}
							/>
						) : null}
					</div>
				</div>
				<div className="map_location_wrap">
					<MapCountryList
						headingTitle="country"
						headingPlaceholder="select country first"
						countryList={mapData.countries}
						selectedCountry={selectedCountry}
						setSelectedCountry={setSelectedCountry}
					/>
					{citiesCountry && citiesCountry.length && (
						<MapCityList
							headingTitle="city"
							headingPlaceholder="select country first"
							cityList={citiesCountry}
							selectedCity={selectedCity}
							setSelectedCity={setSelectedCity}
						/>
					)}
				</div>
			</div>
			{selectedCityLocation && (
				<MapPointsList
					headingTitle="location"
					headingPlaceholder="select country first"
					pointsList={selectedCityLocation}
					highlightedPoints={highlightedPoints}
					setHighlightedPoints={setHighlightedPoints}
				/>
			)}
		</div>
	);
};

export default MapContainer;