import React, { useRef, useState } from "react";
import GoogleMapReact from 'google-map-react';

import {
	MAP_STYLES,
	MAP_API_KEY,
	MAP_DEFAULT_CENTER,
} from './constant';
import MapMarker from './MapMarker';

const Map = ({
	mapPoints,
	highlightedPoints,
	mapCenter,
}) => {

	const mapRef = useRef();
	const points = mapPoints;
	const [zoom2, setZoom] = useState(0);
	const [bounds2, setBounds] = useState(null);

	return (
		<>
			<div className="map">
				<GoogleMapReact
					bootstrapURLKeys={{ key: MAP_API_KEY }}
					defaultCenter={MAP_DEFAULT_CENTER}
					center={mapCenter}
					defaultZoom={0}
					options={map => ({
						mapTypeId: map.MapTypeId.ROADMAP,
						styles: MAP_STYLES,
						zoomControl: true,
						keyboardShortcuts: false,
						zoomControlOptions: {
							position: map.ControlPosition.LEFT_BOTTOM,
						},
					})}
					yesIWantToUseGoogleMapApiInternals
					onGoogleApiLoaded={({ map }) => {
						mapRef.current = map;
					}}
				>
					{mapPoints && mapPoints.map(({
						point_coords,
						id,
					}, index) => {
						const { lat, lng } = point_coords;
						const isActive = highlightedPoints.includes(id);

						if (lat !== '') {
							return (
								<MapMarker
									lat={lat}
									lng={lng}
									isActive={isActive}
									key={`marker_${index}`}
								/>
							);
						}
					})}
				</GoogleMapReact>
			</div>
		</>
	)
};

export default Map;