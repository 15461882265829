import React, { useState, useContext, useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import { JsonDataContext } from 'context/jsonData';
import MenuListItem from './MenuListItem';
import { getType } from 'utils';

const MenuList = () => {
	const { navMenuData, setDropDownActiveMenuItem, setMainMenuActiveItemId } = useContext(JsonDataContext);
	const [sortedNavMenuData, setSortedNavMenuData] = useState(null);

	const mainMenuListEl = useRef(null);
	const location = useLocation();
	const [isSubItemVisible, setSubItemVisible] = useState(true);

	useEffect(() => {
		if (navMenuData) {
			// REFACTOR - GVNK, use recursion
			const tempMenu = Object.keys(navMenuData).map(key => {
				const menuItem = {...navMenuData[key]};

				if (menuItem.sub_menu && getType(menuItem.sub_menu) === 'Object') {
					var subMenu = Object.keys(menuItem.sub_menu).map(subKey => {
						const subMenuItem = {...menuItem.sub_menu[subKey]};

						if (subMenuItem.sub_menu && getType(subMenuItem.sub_menu) === 'Object') {
							var subChildMenu = Object.keys(subMenuItem.sub_menu).map(subChildKey => {
								return {
									...subMenuItem.sub_menu[subChildKey]
								}
							});

							subMenuItem.sub_menu = subChildMenu;
						}

						return {
							...subMenuItem
						};
					});

					menuItem.sub_menu = subMenu;
				}

				return {
					...menuItem
				};
			});

			const sortNestedArray = (item) => {
				if (item.sub_menu && getType(item.sub_menu)) {
					item.sub_menu.sort((a, b) => {
						sortNestedArray(a);
						sortNestedArray(b);

						return a.menu_order - b.menu_order;
					});
				}
			};

			const sortedMenu = tempMenu.sort((a, b) => {
				sortNestedArray(a);
				sortNestedArray(b);

				return a.menu_order - b.menu_order;
			});

			setSortedNavMenuData(sortedMenu);
		}
	}, [navMenuData]);

	// gvnk
	useEffect(() => {
		if (location && location.pathname === '/' || location.pathname === '/search') {
			setDropDownActiveMenuItem({
				rootMenuItemId: -1
			});
			setMainMenuActiveItemId(0);
		}
	}, [location]);

	return (
		sortedNavMenuData && (
			<ul className="main_menu_list" ref={mainMenuListEl}>
				{sortedNavMenuData.map(({
					item_title,
					item_url,
					sub_menu,
					id,
				}, index) => {
					return (
						<MenuListItem
							key={index}
							id={id}
							title={item_title}
							url={item_url}
							subMenu={sub_menu}
							isSubItemVisible={isSubItemVisible}
							setSubItemVisible={setSubItemVisible}
						/>
					);
				})}
			</ul>
		)
	);
};

export default MenuList;