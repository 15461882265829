import React, {useEffect, useContext, useState, useRef} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';

import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';

import { JsonDataContext } from 'context/jsonData';
import {getPageById} from 'utils';


const Solution1 = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 155);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}

	};


	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);


	const pageContent = currentPageData && currentPageData.content;



	return (
		currentPageData && (
			<section className="section bg_v2_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<div className="section_in">
					<SectionHead
						title={pageContent[0].title}
						titleH1
						description={pageContent[0].description}
					/>
					<div className="solution_step_list row col col_5 offset_1">
						{pageContent[1] && pageContent[1].list_items && pageContent[1].list_items.map(({ text }, index) => {
							return (
								<div className="solution_step col col_lg_1 col_tab_1 col_mob_3" key={`solution_step_${index}`}>
									{text && (
										<div className="solution_step_content">
											{ReactHtmlParser(text)}
											{/* <div className="solution_step_title">{title}</div> */}
											{/* <div className="solution_step_text">{text}</div> */}
										</div>
									)}
								</div>
							)
						})}
					</div>
					{pageContent.map((item, index) => {
						if (index > 1) {

							const { title, text } = item;
							let preparedClassName;

							if (index === 3) {
								preparedClassName = 'list_block_wrap offset_mod v2_mod col col_4 offset_2';
							} else {
								preparedClassName = 'list_block_wrap offset_mod col col_5 offset_1';
							}

							return (
								<div className={preparedClassName} key={`list_block_${index}`}>
									<h4 className="list_block_title">{title}</h4>
									<div className="list_block">
										{ReactHtmlParser(text)}
									</div>
								</div>
							)
						}
					})}
				</div>
			</section>
		)
	);
};

export default Solution1;