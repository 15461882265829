import React, { useContext, useState, useEffect } from 'react';

import { searchByEveryThing } from 'api/data';
import { searchFieldValidation } from 'utils';
import { JsonDataContext } from 'context/jsonData';

const SearchForm = ({
	setSearchResult,
	setSearchLoading,
	setFormSubmitted,
	isFormSubmitted,
	setFetchError,
}) => {
	const { globalSearchQuery, setGlobalSearchQuery } = useContext(JsonDataContext);
	const [inputValue, setInputValue] = useState(globalSearchQuery);

	const onChange = e => {
		const { value } = e.target;

		setInputValue(value);
	};

	const onSubmit = async (e, searchQuery) => {
		const preparedSearchQuery = searchQuery || inputValue;

		if (e) {
			e.preventDefault();
		}

		if (searchFieldValidation(preparedSearchQuery)) {
			try {
				setSearchLoading(true);
				setFormSubmitted(true);

				const res = await searchByEveryThing(preparedSearchQuery);

				if (res && res.data) {
					setSearchResult(res.data);
					setSearchLoading(false);
					setGlobalSearchQuery(preparedSearchQuery);
					setFormSubmitted(false);
				}
			} catch (err) {
				setFetchError(true);
				setFormSubmitted(false);
				setSearchLoading(false);
			}
		}
	};

	useEffect(() => {
		if (searchFieldValidation(globalSearchQuery) && !isFormSubmitted) {
			onSubmit(null, globalSearchQuery);
			setInputValue(globalSearchQuery);
		}
	}, [globalSearchQuery]);

	return (
		<form className="search_form" onSubmit={onSubmit} noValidate>
			<dl className="form_cell form_cell_v1_mod no_offset_mod">
				<dt className="form_cell_title form_v1_mod hline_hide_mod">
					<label htmlFor="search_input" />
				</dt>
				<dd className="form_field_wrap form_v1_mod hline_hide_mod">
					<input
						className="form_field default_mod v2_mod"
						type="text"
						id="search_input"
						placeholder="Search"
						value="Avelocom"
						onChange={onChange}
						defaultValue={inputValue}
						value={inputValue}
					/>
				</dd>
			</dl>
		</form>
	);
};

export default SearchForm;