import React, { useEffect, useContext, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';

import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';

import { JsonDataContext } from 'context/jsonData';
import { getPageById } from 'utils';
import { BtnBase } from 'components/BtnBase';

const BusinessInternetPage = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 1221);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);


	return (
		currentPageData && (
			<section className="section bg_v3_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<div className="section_in">
					{currentPageData.page_head && currentPageData.page_head.title && (
						<SectionHead
							title={currentPageData.page_head.title}
							titleH1
						/>
					)}
					{currentPageData.section_description && currentPageData.section_description.title && (
						<h2 className="section_title size_mod col col_4 offset_2 offset_mod" dangerouslySetInnerHTML={{ __html: currentPageData.section_description.title }} />
					)}
					{currentPageData.section_description && currentPageData.section_description.text && (
						<div className="section_descr color_mod col col_5 offset_mod offset_1" dangerouslySetInnerHTML={{ __html: currentPageData.section_description.text }} />
					)}
					{currentPageData.text_columns && currentPageData.text_columns.text_block && (
						<div className="product_list row col col_5 offset_1 offset_2_mod">
							{currentPageData.text_columns.text_block.map((item, index) => {
								return (
									<div className="product_item col col_lg_1 col_tab_1 col_mob_3" dangerouslySetInnerHTML={{ __html: item.text }} key={`product_item_${index}`}/>
								);
							})}
						</div>
					)}
					{currentPageData.section_description_2 && currentPageData.section_description_2.title && (
						<h4 className="section_title size_mod col col_4 offset_2 offset_mod">{currentPageData.section_description_2.title}</h4>
					)}
					{currentPageData.section_description_2 && currentPageData.section_description_2.text && (
						<div className="section_descr color_mod col col_5 offset_1" dangerouslySetInnerHTML={{ __html: currentPageData.section_description_2.text }} />
					)}
					{currentPageData.cta_button && currentPageData.cta_button.button_text && (
						<BtnBase
							buttonSettings={currentPageData.cta_button}
							offsetMod
						/>
					)}
				</div>
			</section>
		)
	);
};

export default BusinessInternetPage;