import { ReactComponent as Linkedin } from 'i/icons/linkedin.svg';
import { ReactComponent as Twitter } from 'i/icons/twitter.svg';
import { ReactComponent as Search } from 'i/icons/search.svg';

export const SOCIAL_LIST = [
	{
		url: 'https://www.linkedin.com/company/avelacom',
		Icon: Linkedin,
	},
	{
		url: 'https://twitter.com/Avelacom_Corp',
		Icon: Twitter,
	},
	{
		url: '#',
		Icon: Search,
		isPopup: true,
	},
]