import { ReactComponent as ImgMap1 } from 'i/map_1.svg'
import { ReactComponent as ImgMap2 } from 'i/map_2.svg'
import { ReactComponent as ImgMap3 } from 'i/map_3.svg'

import { ReactComponent as ImgMap } from 'i/locations_1.svg'

export const FILTER_OPTIONS = {
	region: 0,
	country: 0,
	city: 0,
	trading: true,
};

export const GLOBAL_SERVICE_CONTENT = [
	{
		id: 0,
		Img: ImgMap1,
		title: 'Avelacom global connectivity map'
	},
	{
		id: 1,
		Img: ImgMap2,
		title: 'Access between trading venues'
	},
	{
		id: 2,
		Img: ImgMap3,
		title: 'Europe – Asia trans Russia network'
	}
] 

export const GLOBAL_SERVICE_TABS = [
	{
		id: 0,
		title: 'Global infrastructure'
	},
	{
		id: 1,
		title: 'ACCESS BETWEEN TRADING VENUES'
	},
	{
		id: 2,
		title: 'EU–Asia trans Russia'
	}
]

export const GLOBAL_LOCATION_CONTENT = [
	{
		id: 0,
		Img: ImgMap,
		locations: [
			{
				title: 'country',
				placeholder: 'select country first',
				mod: 'lg_mod',
				list: [
					'FRANCE',
					'GERMANY',
					'NETHERLANDS',
					'SWEDEN',
					'SWITZERLAND',
					'TURKEY',
					'U.K.',
					'RUSSIA',
					'FRANCE',
					'GERMANY',
					'NETHERLANDS',
					'SWEDEN',
					'SWITZERLAND',
					'TURKEY',
					'U.K.',
					'RUSSIA',	
				]
			},
			{
				title: 'city',
				placeholder: 'select country first',
				scroll: true,
				list: [
					'Krasnojarsk',
					'Irkutsk',
					'Khabarovsk', 
					'Vladivostok',
					'Moscow',
					'St. Petersburg',
					'Ekaterinburg',
					'Novosibirsk'
				]
			}
		]
	},
	{
		id: 1,
		Img: ImgMap1
	},
	{
		id: 2,
		Img: ImgMap2
	},
	{
		id: 3,
		Img: ImgMap3
	}
];

export const GLOBAL_LOCATION_TABS = [
	{
		id: 0,
		title: 'All'
	},
	{
		id: 1,
		title: 'EMEA'
	},
	{
		id: 2,
		title: 'APAC'
	},
	{
		id: 3,
		title: 'AMER'
	},
	
]