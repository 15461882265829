import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const MapMarker = ({
	isActive
}) => {
	return (
		<div className={`map_marker ${isActive ? 'active_mod' : ''}`}></div>
	);
};

export default MapMarker;
