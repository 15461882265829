import React, { useState, useEffect, useRef } from 'react';
import { TABLET_MEDIA_POINT } from 'utils/constant';
import { checkTrueValueInArray } from 'utils';
import AnimationContext from './animationContext';

const AnimationState = ({ children }) => {
	const [isPreloaderAnimDone, setPreloaderAnimDone] = useState(false);
	const [isPreloaderProgressAnimDone, setPreloaderProgressAnimDone] = useState(false);
	const [isHeroAnimDone, setHeroAnimDone] = useState(false);
	const [logoClicked, setLogoClicked] = useState(false);
	const [windowWidth, setWindowWidth] = useState(null);
	const [isPopupsOpen, setPopupsOpen] = useState(false);
	const [mainMenuOpen, setMainMenuOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [prevLocation, setPrevLocation] = useState(null);
	const [newsSliderCurrentSlide, setNewsSliderCurrentSlide] = useState(4);
	const bodyEl = useRef(null);
	const [appPopups, setAppPopups] = useState({
		search: {
			isOpen: false,
		},
		getInTouch: {
			isOpen: false,
		},
		customerSupport: {
			isOpen: false,
		},
		salesInquiry: {
			isOpen: false,
		},
		request: {
			isOpen: false,
		},
		join: {
			isOpen: false,
		},
		signingUp: {
			isOpen: false,
		},
		gallery: {
			isOpen: false,
		}
	});

	const onLoad = () => {
		if (window.innerWidth >= TABLET_MEDIA_POINT) {
			setIsMobile(false);
		} else {
			setIsMobile(true);
		}
	};

	const onResize = () => {
		setWindowWidth(window.innerWidth);
	};

	useEffect(() => {
		if (windowWidth >= TABLET_MEDIA_POINT && isMobile) {
			setIsMobile(false);
		} else if (windowWidth <= TABLET_MEDIA_POINT && !isMobile) {
			setIsMobile(true);
		}
	}, [windowWidth]);

	const toggleMainMenuState = () => {
		setMainMenuOpen(!mainMenuOpen);
		setPopupsOpen(prevState => !prevState);
	};

	const showPopupByKey = (popupKey) => {
		const updatedAppPopups = { ...appPopups };
		const isOpenFlags = [];

		Object.keys(updatedAppPopups).forEach(key => {
			const keyObj = updatedAppPopups[key];
			if (popupKey && key.toLowerCase() === popupKey.toLowerCase()) {
				keyObj.isOpen = !keyObj.isOpen;
			} else {
				keyObj.isOpen = false;
			}

			isOpenFlags.push(keyObj.isOpen);
		});

		let popupState = checkTrueValueInArray(isOpenFlags);

		setPopupsOpen(popupState);
		setAppPopups(updatedAppPopups);
	};

	useEffect(() => {
		bodyEl.current = document.querySelector('body');
		onResize();
		onLoad();
		window.addEventListener('resize', onResize);
	}, []);

	return (
		<AnimationContext.Provider
			value={{
				isPreloaderAnimDone,
				setPreloaderAnimDone,
				isPreloaderProgressAnimDone,
				setPreloaderProgressAnimDone,
				isHeroAnimDone,
				setHeroAnimDone,
				windowWidth,
				isPopupsOpen,
				setPopupsOpen,
				bodyEl,
				toggleMainMenuState,
				mainMenuOpen,
				setMainMenuOpen,
				isMobile,
				appPopups,
				setAppPopups,
				showPopupByKey,
				newsSliderCurrentSlide,
				setNewsSliderCurrentSlide,
				prevLocation,
				setPrevLocation,
				logoClicked,
				setLogoClicked
			}}
		>
			{children}
		</AnimationContext.Provider>
	);

};

export default AnimationState;