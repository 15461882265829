import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import { AnimationContext } from 'context/animation';

import { ReactComponent as ImgMap1 } from 'i/map_1.svg'
import { ReactComponent as ImgMap2 } from 'i/map_2.svg'
import { ReactComponent as ImgMap3 } from 'i/map_3.svg'

// REFACTOR

import { ReactComponent as CloseIcon } from 'i/icons/close_icon.svg';

const ImgPopup = ({
	isOpen,
	Img
}) => {
	const { showPopupByKey } = useContext(AnimationContext);

	const handleClosePopup = () => {
		showPopupByKey();
	};

	return (
		<CSSTransition
			in={isOpen}
			timeout={{
				enter: 500,
				exit: 350,
			}}
			classNames="popup"
			mountOnEnter
			unmountOnExit
		>
			<div className="popup v3_mod search_mod">
				<div className="overlay" onClick={handleClosePopup} />
				<div className="popup_in">
					<div className="popup_in_content">
						<div className="popup_close icon_mod" onClick={handleClosePopup}>
							<CloseIcon className="popup_close_icon color_mod" />
						</div>
						<div className="popup_img_wrap">
							{Img && <img className="popup_img" src={Img} />}
							{/* {Img && <Img className="popup_img" />} */}
						</div>
					</div>
				</div>
			</div>
		</CSSTransition>
	)
};

export default ImgPopup;
