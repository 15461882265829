import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import {BaseLink} from 'components/BaseLink'

const ProductsItem = ({
	title,
	text,
	url,
}) => {
	const titleUrl = url && url.url ? url.url : '#';

	return (
		<div className="product_item col col_lg_1 no_offset_mod offset_mod">
			<div className="product_title_wrap">
				<BaseLink
						url={titleUrl}
						text={ReactHtmlParser(title)}
						linkClassName={'product_title'}
				/>
			</div>
			<div className="product_text" dangerouslySetInnerHTML={{ __html: text }} />
		</div>
	);
};

export default ProductsItem;

ProductsItem.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
};