import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from 'i/icons/close.svg';
import { AnimationContext } from 'context/animation';
import { TABLET_MEDIA_POINT } from 'utils/constant';
import { CSSTransition } from 'react-transition-group';
import { nameValidation, emailValidation, fieldValidation } from 'utils';
import { FormFormik } from 'components/Form';
import APP_DATA from 'utils/jsonAppData';
import PopupClose from "./PopupClose";

// const EMAIL_SUBJECT = 'Feedback from site Avelacom.com (Sales)';
// const EMAIL_SUBJECT_WITH_UPDATE = 'Feedback from site Avelacom.com (Sales + News)';

const SalesInquiryPopup = ({
	isOpen,
}) => {
	const { windowWidth, showPopupByKey } = useContext(AnimationContext);

	const validate = {
		fullName: name => nameValidation("Name", name),
		email: emailValidation,
		company: () => { return null },
		phone: () => { return null },
		companyLocation: () => { return null },
		help: () => { return null },
		newsSubscribe: () => { return null },
		subjectEmail: () => { return null },
	};

	const initialValues = {
		fullName: '',
		company: '',
		email: '',
		phone: '',
		companyLocation: '',
		help: '',
		newsSubscribe: false,
		subjectEmail: APP_DATA.commonSubjectEmail,
	};

	const handleClosePopup = () => {
		showPopupByKey();
	};

	return (
		<CSSTransition
			in={isOpen}
			timeout={{
				enter: 500,
				exit: 350,
			}}
			classNames="popup"
			mountOnEnter
			unmountOnExit
		>
			<div className="popup request_mod">
				<div className="overlay" onClick={handleClosePopup} />
				<div className="popup_in request_mod">
					<div className="section_head offset_v6_mod">
						{windowWidth >= TABLET_MEDIA_POINT ? (
							<PopupClose onClickHandler={handleClosePopup} />
						) : null}
						<div className="section_w_title">
							<div className="section_title">Sales Inquiry</div>
							{windowWidth < TABLET_MEDIA_POINT ? (
								<div className="popup_close_btn" onClick={handleClosePopup}>
									<CloseIcon className="popup_close_icon" />
								</div>
							) : null}
						</div>
						<div className="section_descr color_mod offset_l_mod">Please fill out this form, and we’ll get in touch with you shortly</div>
					</div>
					<FormFormik validate={validate} initialValues={initialValues} />
				</div>
			</div>
		</CSSTransition>
	);
};

export default SalesInquiryPopup;

// SalesInquiryPopup.propTypes = {
// 	handleOpenPopup: PropTypes.func.isRequired,
// };