import React from 'react';
import Select from 'react-select';

const InputForm = ({
	id,
	type,
	placeholder,
	name,
	values,
	required,
	handleBlur,
	options,
	handleChange,
	touched,
	errors,
	setFieldValue,
}) => {
	// const handleSelectOnChange = e => {
	// 	const { value, label } = e;

	// 	setFieldValue(name, label);
	// };

	return (
		<dl className="form_cell form_cell_v1_mod half_mod">
			<dt className="form_cell_title form_v1_mod hline_hide_mod">
				<label htmlFor={id} />
			</dt>
			<dd className="form_field_wrap form_v1_mod hline_hide_mod">
				{type === 'textarea' && (
					<textarea
						className="form_field default_mod textarea_mod"
						type={type}
						id={id}
						placeholder={placeholder}
						name={name}
						value={values[name]}
						required={required}
						onBlur={handleBlur}
						onChange={handleChange}
					/>
				)}
				{type === 'select' && (
					<Select
						classNamePrefix='select'
						options={options}
						placeholder='I am interested in'
						name={name}
						onChange={(e) => setFieldValue(name, e.label)}
					/>
				)}
				{type !== 'textarea' && type !== 'select' && (
					<input
						className="form_field default_mod"
						type={type}
						id={id}
						placeholder={placeholder}
						name={name}
						value={values[name]}
						required={required}
						onBlur={handleBlur}
						onChange={handleChange}
					/>
				)}
				<div className="form_error">{touched[name] && errors[name]}</div>
			</dd>
		</dl>
	);
};

export default InputForm;