import React, { useState, useContext, useEffect, useRef } from 'react';
import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';
import { AnimationContext } from 'context/animation';
import { Helmet } from 'react-helmet';

import IconMail from 'i/mail.png';

import { getPageById } from 'utils';
import { JsonDataContext } from 'context/jsonData';

const ContactPage = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const { showPopupByKey } = useContext(AnimationContext);
	const [isCustomerPopupOpen, setCustomerPopupOpen] = useState(null);
	const [isRequestPopupOpen, setRequestPopupOpen] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);
	const popupCustomerEl = useRef(null);
	const popupRequestEl = useRef(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 13);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (
				preparedCurrentPageData.title &&
				preparedCurrentPageData.title.rendered
			) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	const handleOpenCustomerPopup = () => {
		showPopupByKey('customerSupport');
		// popupCustomerEl.current.classList.toggle('active_mod');
		// setCustomerPopupOpen(prevState => !prevState);
	};

	const handleOpenRequestPopup = () => {
		showPopupByKey('salesInquiry');
		// popupRequestEl.current.classList.toggle('active_mod');
		// setRequestPopupOpen(prevState => !prevState);
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	return (
		currentPageData && (
			<>
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<section className="section bg_v5_mod">
					<Decors />
					<div className="section_in">
						<SectionHead title={currentPageData.content[0].title} />
						<div className="contacts_list row">
							{currentPageData.contact &&
								currentPageData.contact.map(
									({ city, address, email, phone }, index) => {
										let blockClass;

										if (index === 0) {
											blockClass = 'col col_1 offset_1 col_tab_1 col_mob_3';
										} else {
											blockClass = 'col col_1 col_tab_1 col_mob_3';
										}

										return (
											<div className={blockClass} key={index}>
												<div className="contacts_item">
													<div className="section_title small_mod offset_v2_mod">
														{city}
													</div>
													<div className="contacts_item_address">{address}</div>
													<ul className="contacts_info_list">
														{currentPageData.contact && phone && (
															<li className="contacts_info_item">
																<a
																	className="contact_info_link"
																	href={`skype:${phone}`}>
																	{phone}
																</a>
															</li>
														)}
														{/* <li className="contacts_info_item"><a className="contact_info_link" href={`mailto:${email}`}>{email}</a></li> */}
														{currentPageData.contact && email && (
															<li className="contacts_info_item">
																<a
																	className="contact_info_link"
																	href={`mailto:${email}`}>
																	<div className="contact_info_icon">
																		<img
																			className="contact_info_icon_in"
																			src={IconMail}
																			alt="email"
																		/>
																	</div>
																	email
																</a>
															</li>
														)}
													</ul>
												</div>
											</div>
										);
									}
								)}
						</div>
						{/* <h2 className="col col_1 offset_3 section_title small_mod offset_in_mod color_mod">contact <br/> us</h2> */}
						<h2 className="col col_1 offset_3 section_title size_mod offset_v4_mod color_mod">
							Contact us
						</h2>
						<div className="contacts_form_inner row">
							{/* <div className="col col_2 offset_2 no_offset_in_mod col_tab_1 col_mob_3">
								<button
									className="contacts_form_button"
									onClick={handleOpenCustomerPopup}
								>Customer support
								</button>
							</div>
							<div className="col col_2 no_offset_in_mod col_tab_1 offset_tab_1 col_mob_3">
								<button
									className="contacts_form_button"
									onClick={handleOpenRequestPopup}
								>Sales inquiry
								</button>
							</div> */}

							<div className="contacts_form_inner_item">
								<button
									className="btn_sm v2_mod"
									onClick={handleOpenCustomerPopup}>
									<div className="btn_in">Customer support</div>
								</button>
							</div>
							<div className="contacts_form_inner_item">
								<button
									className="btn_sm v2_mod"
									onClick={handleOpenRequestPopup}>
									<div className="btn_in">Sales inquiry</div>
								</button>
							</div>
						</div>
					</div>
				</section>
			</>
		)
	);
};

export default ContactPage;
