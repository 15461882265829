import React, { useContext } from 'react';
import { AnimationContext } from 'context/animation';
import SubMenuListItem from './SubMenuListItem';
import classNames from 'classnames';

const SubMenuList = ({
	menuList,
	staticMod,
	rootMenuItemId,
	tlMenuAnim,
}) => {
	const subMenuListClasses = classNames('submenu_list', {
		static_mod: staticMod,
	});

	return (
		<ul className={subMenuListClasses}>
			{menuList.map(({
				item_title,
				item_url,
				sub_menu,
				ID,
			}, index) => {
				return (
					<SubMenuListItem
						id={ID}
						title={item_title}
						url={item_url}
						subMenu={sub_menu}
						rootMenuItemId={rootMenuItemId}
						tlMenuAnim={tlMenuAnim}
						key={`menu_item_${index}`}
					/>
				);
			})}
		</ul>
	);
};

export default SubMenuList;