import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Decors } from 'components/Decors';
import { Helmet } from 'react-helmet';

import { JsonDataContext } from 'context/jsonData';
import { AnimationContext } from 'context/animation';
import { getPageById, getPathNameFromUrl } from 'utils';

import { ImgPopup } from 'components/Popup';

const GlobalServiceIn = ({
	currentTab
}) => {
	let prevTab, nextTab;
	const [popupImg, setPopupImg] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);

	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const { showPopupByKey, appPopups } = useContext(AnimationContext);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 675);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	const handleOpenImgPopup = Img => {
		showPopupByKey('gallery');

		if (Img) {
			setPopupImg(Img)
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	if (currentPageData) {
		prevTab = currentTab ? currentTab - 1 : currentPageData.map_tabs.length - 1;
		nextTab = currentTab == currentPageData.map_tabs.length - 1 ? 0 : currentTab + 1;
	}

	return (
		currentPageData && (
			<section className="section offset_top_mod bg_v4_mod offset_bottom_2_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<Link className="page_nav prev_mod" to={currentPageData.map_tabs[prevTab].tab_link}>{currentPageData.map_tabs[prevTab].tab_title}</Link>
				<Link className="page_nav next_mod" to={currentPageData.map_tabs[nextTab].tab_link}>{currentPageData.map_tabs[nextTab].tab_title}</Link>
				<div className="section_in">
					<div className="tab_content_wrap row col col_5 offset_1">
						<div className={`tab_content map_mod active_tab`}>
							<h1 className="section_title size_v2_mod offset_mod mob_size_mod">{currentPageData.map_tabs[currentTab].map_title}</h1>
							<div className="global_map_wrap">
								<img className="global_map_img" src={currentPageData.map_tabs[currentTab].map.url} onClick={() => {
									handleOpenImgPopup(currentPageData.map_tabs[currentTab].map.url);
								}} />
							</div>
						</div>
					</div>
					<div className="row center_mod col col_5 offset_1">
						{currentPageData.map_tabs.map((item, index) => {
							return (
								<Link to={item.tab_link} className={`tab_link map_mod col col_lg_1 ${index === currentTab ? 'active_tab' : ''}`} key={index}>{item.tab_title}</Link>
							)
						})}
					</div>
				</div>
				<ImgPopup isOpen={appPopups.gallery.isOpen} Img={popupImg}
				/>
			</section>
		)
	)
}

export default GlobalServiceIn;
