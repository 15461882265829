import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';
import { JsonDataContext } from 'context/jsonData';
import { getPageById } from 'utils';

const AwardsPage = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 657);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData])

	return (
		currentPageData && (
			<section className="section bg_v1_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<div className="section_in">
					<SectionHead
						title={currentPageData.content[0].title}
						titleH1
						offsetV6Mod
					/>
					<div className="press_list row col col_3 offset_2">
						{currentPageData.content[1].logos.map((logo, index) => {
							return (
								logo.link.length ? (
										<a className="press_item" href={logo.link} key={`logo_${index}`}>
											<div className="press_img_wrap">
												<img className="press_img" src={logo.image.url} alt={logo.image.alt} />
											</div>
										</a>
										) : (
										<div className="press_item" key={`logo_${index}`}>
											<div className="press_img_wrap">
												<img className="press_img" src={logo.image.url} alt={logo.image.alt} />
											</div>
										</div>
										)
							)
						},)}
					</div>
				</div>
			</section>
		)
	);
};

export default AwardsPage;