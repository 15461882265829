import React, { useEffect, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';

import { JsonDataContext } from 'context/jsonData';
import { getPageById } from 'utils';
import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';
import {BtnBase} from "../../components/BtnBase";
import ReactHtmlParser from "react-html-parser";

const CryptoTradingPage = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 1191);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	return (
		currentPageData && (
			<section className="section bg_v2_mod offset_bottom_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<div className="section_in">
					{currentPageData.page_head.title && currentPageData.page_head.description && (
						<SectionHead
							title={currentPageData.page_head.title}
							titleH1
							description={currentPageData.page_head.description}
						/>
					)}
					{currentPageData.text_columns && currentPageData.text_columns.text_block && (
						<div className="product_list row col col_5 offset_1 center_mod offset_mod">
							{currentPageData.text_columns.text_block.map(({ text }) => {
								return (
									<div className="product_item col col_lg_1 col_tab_1" dangerouslySetInnerHTML={{ __html: text }} />
								)
							})}
						</div>
					)}
					<div className="list_block_wrap row col col_5 offset_1 offset_mod">
						<div className="list_block_wrap col col_lg_3 col_tab_2 mobile_offset_mod">
							{currentPageData.text_block_with_title && (
								<>
									{currentPageData.text_block_with_title.title ? (
										<h4 className="list_block_title">{currentPageData.text_block_with_title.title}</h4>
									) : null}
									{currentPageData.text_block_with_title.text && (
										<div className="">
											<div className="list_block" dangerouslySetInnerHTML={{ __html: currentPageData.text_block_with_title.text }} />
										</div>
									)}
								</>
							)}
						</div>
						{currentPageData.cta_button && currentPageData.cta_button.button_text && currentPageData.cta_button.link && (
								<BtnBase
										buttonSettings={currentPageData.cta_button}
										centerMod
										vertCenterMod
										col
										colLg2
										colTab1
										btnSm
								/>
						)}
					</div>
				</div>
			</section>
		)
	)
}

export default CryptoTradingPage;