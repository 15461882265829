import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import SubMenuList from './SubMenuList';
import { getType } from 'utils';
import { TABLET_MEDIA_POINT } from 'utils/constant';
import { AnimationContext } from 'context/animation';
import { JsonDataContext } from 'context/jsonData';

const SubMenuListItem = ({
	title,
	url,
	subMenu,
	id,
	rootMenuItemId,
	tlMenuAnim,
}) => {
	const { windowWidth, toggleMainMenuState} = useContext(AnimationContext);
	const { setDropDownActiveMenuItem, setMainMenuActiveItemId } = useContext(JsonDataContext);
	const location = useLocation();
	const [isActiveItem, setActiveItem] = useState(false);

	const handleOnClick = () => {

		if (windowWidth >= TABLET_MEDIA_POINT && tlMenuAnim) {
			tlMenuAnim.reverse();
		}

		toggleMainMenuState();
		setMainMenuActiveItemId(rootMenuItemId);
	};

	useEffect(() => {
		if (url === location.pathname) {
			const subMenuItem = { title, rootMenuItemId };
			setDropDownActiveMenuItem(subMenuItem);
			setMainMenuActiveItemId(rootMenuItemId);
			setActiveItem(true);
		} else {
			setActiveItem(false);
		}
	}, [location]);

	return (
		<li className="submenu_item">
			<div className="main_menu_subitem_w">
				<NavLink
						className={`main_menu_subitem ${isActiveItem ? 'active_mod' : ''}`}
						to={url}
						onClick={handleOnClick}
				>
					{title}
				</NavLink>
			</div>
			{subMenu && subMenu.length && windowWidth >= TABLET_MEDIA_POINT ? (
				<SubMenuList
					menuList={subMenu}
					staticMod
					rootMenuItemId={rootMenuItemId}
					tlMenuAnim={tlMenuAnim}
				/>
			) : null}
		</li>
	);
};

export default SubMenuListItem;