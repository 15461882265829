import React, { useState } from 'react';

const MapRadioControl = ({
	id,
	title,
	inputName,
	checked,
	mapCheckboxState,
	setMapCheckboxState,
}) => {

	const handleOnChange = e => {
		const { name, value, checked } = e.target;

		const updatedCheckBoxState = { ...mapCheckboxState };

		if (mapCheckboxState[inputName] !== true) {
			Object.keys(updatedCheckBoxState).map(key => {
				if (key === name) {
					updatedCheckBoxState[key] = checked;
				} else {
					updatedCheckBoxState[key] = false;
				}
			});

			setMapCheckboxState(updatedCheckBoxState);
		}
	};

	return (
		<div className="map_control_item">
			<label className="radio_check radio_1_mod">
				<input
					className="radio_check_input"
					type="checkbox"
					id={id}
					name={inputName}
					checked={checked}
					onChange={handleOnChange}
				/>
				<span className="radio_check_text radio_1_mod">{title}</span>
			</label>
		</div>
	);
};

export default MapRadioControl;