import React, { useEffect, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';

import { JsonDataContext } from 'context/jsonData';
import { getPageById } from 'utils';
import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';
import { BtnBase } from 'components/BtnBase';

const Solution4 = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 496);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	const pageContent = currentPageData && currentPageData.content;


	return (
		currentPageData && (
			<section className="section bg_v2_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<div className="section_in">
					<SectionHead
						title={pageContent[0].title}
						titleH1
						description={pageContent[0].description}
					/>
					<div className="list_block_wrap row col col_5 offset_1 offset_mod">
						<div className="list_block_wrap col col_lg_5">
							<h4 className="list_block_title">{pageContent[1].title}</h4>
							{pageContent[1].text && (
								<div className="list_block" dangerouslySetInnerHTML={{ __html: pageContent[1].text }} />
							)}
						</div>
					</div>
					<BtnBase
						buttonSettings={pageContent[2].cta_button}
						centerMod
					/>
				</div>
			</section>
		)
	)
}

export default Solution4;