import React, { useEffect, useState } from 'react';

import JsonDataContext from './jsonDataContext';
import { getAllPages, getNavMenuItems, getAppBaseData } from 'api/data';

const JsonDataState = ({ children }) => {
	const [pagesData, setPagesData] = useState(null);
	const [navMenuData, setNavMenuData] = useState(null);
	const [allNews, setAllNews] = useState([]);
	const [currentNewsId, setCurrentNewsId] = useState(null);
	const [isHeaderOverlay, setIsHeaderOverlay] = useState(false);
	const [headerOverlayMod, setHeaderOverlayMod] = useState(null);
	const [pageScrollTop, setPageScrollTop] = useState(0);
	const [appBaseData, setAppBaseData] = useState(null);
	const [globalSearchQuery, setGlobalSearchQuery] = useState('');
	const [dropDownActiveMenuItem, setDropDownActiveMenuItem] = useState({
		parentId: -1
	});
	const [mainMenuActiveItemId, setMainMenuActiveItemId] = useState(null);

	const fetchAppBaseData = async () => {
		try {
			const res = await getAppBaseData();

			return res.data;
		} catch (err) {
			console.error(err);
		}

		return null;
	};

	const getAllPagesData = async () => {
		try {
			const resultPagesData = await getAllPages();

			return resultPagesData;
		} catch (err) {
			console.log(err);
		}
	};

	const getNavMenuItemsData = async () => {
		try {
			const resultNavMenuItemsData = await getNavMenuItems();

			return resultNavMenuItemsData;
		} catch (err) {
			console.log(err);
		}
	};

	const getAllAppData = async () => {
		const resultAllPagesData = await getAllPagesData();
		const allMenuItemsData = await getNavMenuItemsData();
		const allBaseAppOptions = await fetchAppBaseData();

		if (allBaseAppOptions && allBaseAppOptions.acf) {
			setAppBaseData(allBaseAppOptions.acf);
		}

		if (resultAllPagesData && resultAllPagesData.data) {
			setPagesData(resultAllPagesData.data);
		}

		if (allMenuItemsData && allMenuItemsData.data) {
			setNavMenuData(allMenuItemsData.data);
		}

	};

	useEffect(() => {
		getAllAppData();
	}, []);

	return (
		<JsonDataContext.Provider
			value={{
				pagesData,
				navMenuData,
				allNews,
				setAllNews,
				currentNewsId,
				setCurrentNewsId,
				isHeaderOverlay,
				setIsHeaderOverlay,
				headerOverlayMod,
				setHeaderOverlayMod,
				pageScrollTop, 
				setPageScrollTop,
				appBaseData,
				dropDownActiveMenuItem,
				setDropDownActiveMenuItem,
				mainMenuActiveItemId,
				setMainMenuActiveItemId,
				globalSearchQuery,
				setGlobalSearchQuery,
			}}
		>
			{children}
		</JsonDataContext.Provider>
	);
};

export default JsonDataState;