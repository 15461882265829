// import {useRef} from "react";

export const getPageBySlug = (allPages, pageSlug) => {
	const resultPage = allPages.filter(({ slug }) => {
		if (slug === pageSlug) return true;
	});

	return resultPage;
};

export const checkTrueValueInArray = arr => {
	if (arr.includes(true)) {
		return true;
	}
	return false;
}

export const getPageById = (allPages, pageId) => {
	const resultPage = allPages.filter(({ id }) => {
		if (id === pageId) return true;
	});

	return resultPage;
};

export const debounce = (func, wait, immediate) => {
	let timeout;

	return function () {
		let context = this,
			args = arguments;

		let later = function () {

			timeout = null;

			if (!immediate) {
				func.apply(context, args);
			}

		};

		let callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait || 200);

		if (callNow) {
			func.apply(context, args);
		}

	};

};

export const getNewsBySlug = (allNews, newsSlug) => {
	let currentNewsId = null;

	const resultNews = allNews.filter(({ slug }, index) => {
		if (slug === newsSlug) {
			currentNewsId = index;
			return true
		};
	});

	return {
		news: resultNews[0],
		newsId: currentNewsId
	};

};

export const nameValidation = (fieldName, fieldValue) => {
	if (fieldValue.trim() === "") {
		return `${fieldName} is required`;
	}
	if (/[^a-zA-Z -]/.test(fieldValue)) {
		return "Invalid characters";
	}
	if (fieldValue.trim().length < 3) {
		return `${fieldName} needs to be at least three characters`;
	}
	return null;
};

export const emailValidation = email => {
	if (
		/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
			email
		)
	) {
		return null;
	}
	if (email.trim() === "") {
		return "Email is required";
	}
	return "Please enter a valid email";
};

export const searchFieldValidation = fieldValue => {
	if (fieldValue.trim() === '') {
		return false;
	}

	return true;
};

export const fieldValidation = (fieldName, fieldValue) => {
	if (fieldValue.trim() === "") {
		return `${fieldName} is required`;
	}

	return null;
};

export const phoneValidation = (fieldName, fieldValue) => {
	if (fieldValue.trim() === "") {
		return `${fieldName} is required`;
	}

	if (!(/^[0-9]+$/.test(fieldValue))) {
		return "Invalid characters";
	}

	return null;
};

export const getType = (obj) => {
	const type = Object.prototype.toString.call(obj);

	if (type === '[object Object]') {
		return 'Object';
	} else if (type === '[object Array]') {
		return 'Array';
	} else if (type === '[object Boolean]') {
		return 'Boolean';
	} else if (type === '[object Number]') {
		return 'Number';
	} else {
		return 'Literal';
	}
};

export const getPathNameFromUrl = url => {
	const pathName = new URL(url).pathname;

	return pathName;
};

export const getComingUrl = (url) => {

	const getLocation = function(href) {

		let l = document.createElement("a");
		l.href = href;
		return l;
	};

	const convertingUrl = getLocation(url);

	const originUrl = window.location.origin;
	const comingUrl = convertingUrl.origin;
	const comingPath = convertingUrl.pathname;
	const devHostUrl = 'http://localhost:3000';
	const patternCheck = '.pdf';
	const noFile = comingPath.indexOf(patternCheck) !== -1;

	let currentLinkUrl;
	let externalLinkUrl;


	// for localHost
	// if (originUrl === devHostUrl && devHostUrl !== comingUrl && !noFile) {
	// 	currentLinkUrl = comingPath;
	// 	externalLinkUrl = false;
	// } else {
	// 	externalLinkUrl = true;
	// 	currentLinkUrl = url;
	// }

	if (originUrl !== comingUrl && !noFile) {
		currentLinkUrl = comingPath;
		externalLinkUrl = false;
	} else {
		externalLinkUrl = true;
		currentLinkUrl = url;
	}

	return [currentLinkUrl, externalLinkUrl, noFile]

};

export const getNoClassElements = (elem) => {
	const $elementsArray = document.querySelectorAll(elem);
	let noClassElem = [];

	$elementsArray.forEach((item, index) => {
		let isClassName = item.className;

		if (!isClassName) {
			noClassElem.push(item);
		}

	})

	return noClassElem;
}

export const getContentLinks = history => {
	const contentLinks = getNoClassElements('a');

	if (typeof(contentLinks) !== 'undefined' && contentLinks != null) {
		contentLinks.forEach((link) => {
			if (link.href !== null) {
				let contentOriginLink = getComingUrl(link.href);
				let comingLinkPath = contentOriginLink[0];
				let comingLinks = contentOriginLink[1];

				if (!comingLinks) {
					link.addEventListener('click', (event)=> {
						event.preventDefault();
						history.push(comingLinkPath);
					})
				}
			}
		})
	}

}

export const isPreRendering = () => {
	return navigator.userAgent === 'ReactSnap';
};