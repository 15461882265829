import React from 'react';
// import Scrollbar from 'react-scrollbars-custom';

const MapPointsList = ({
	headingTitle,
	headingPlaceholder,
	pointsList,
	highlightedPoints,
	setHighlightedPoints
}) => {

	const handleOnClick = pointId => {
		let updatedHighlightedPoints = [...highlightedPoints];

		if (updatedHighlightedPoints.includes(pointId)) {
			updatedHighlightedPoints = updatedHighlightedPoints.filter(id => {
				if (id !== pointId) return true;
			});
		} else {
			updatedHighlightedPoints.push(pointId);
		}

		setHighlightedPoints(updatedHighlightedPoints);
	};

	return (
		<div className="map_location_col v2_mod">
			{/* <div className="map_location_title">{headingTitle}</div> */}
			<div className="map_location_placeholder">{headingPlaceholder}</div>
			{pointsList && (
				<div className="map_location_list sm_mod">
					{pointsList.map(({
						id,
						point_name,
					}, index) => {
						const isActive = highlightedPoints.includes(id) ? 'label_mod' : '';

						return (
							<div className="map_location_item" key={index}>
								<button
									className={`map_location_button ${isActive}`}
									onClick={() => handleOnClick(id)}
								>
									{point_name}
								</button>
							</div>
						)
					})}
				</div>
			)}
		</div>
	);
};

export default MapPointsList;