import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import { AnimationContext } from 'context/animation';

import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';
import { SectionBg } from 'components/SectionBg';
import { AdvantageItem } from 'components/AdvantageItem';
import {BtnBase} from "../BtnBase";

const HomePageSectionTwo = ({
	sectionData
}) => {
	const {
		section_title,
		section_description,
		section_background_image,
		advantages_slider,
		cta_button_text,
		cta_button_url,
		cta_button
	} = sectionData;

	const { windowWidth } = useContext(AnimationContext);

	let preparedClassName;

	const advantagesClassFunc = (index) => {
		preparedClassName = 'advantages_item col col_lg_1 col_tab_1 col_mob_1';
		let middleIndexEl = Math.round((advantages_slider.length - 1) / 2);

		if (index === advantages_slider.length - 1) {
			preparedClassName += ' right_mod';
		} else if (index === middleIndexEl) {
			preparedClassName += ' large_mod center_mod color_mod';
		}
	}

	const advantagesSliderSettings = {
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		arrows: false,
		centerMode: true,
		centerPadding: '120px',
		responsive: [
			{
				breakpoint: 430,
				settings: {
					centerPadding: '96px',
				}
			},
			{
				breakpoint: 374,
				settings: {
					centerPadding: '90px',
				}
			}
		]
	};

	return (
		<section className="section index_mod offset_mod speedSection">
			<SectionBg
				url={section_background_image.url}
				alt={section_background_image.alt}
			/>
			<Decors />
			<div className="section_in">
				<SectionHead
					title={section_title}
					description={section_description}
					offsetV6Mod
					textColor2
				/>
				{windowWidth > 480 &&
					<div className="advantages_list row col col_5 offset_1 center_mod">
						{advantages_slider.map(({
							title,
							amount,
							text,
							icon,
						}, index) => {
							advantagesClassFunc(index)
							return (
								<AdvantageItem
									icon={icon}
									title={title}
									amount={amount}
									text={text}
									key={index}
									preparedClassName={preparedClassName}
								/>
							)
						})}
					</div>
				}
				{windowWidth <= 480 &&
					<Slider
						{...advantagesSliderSettings}
						className="advantages_list row col col_5 offset_1 center_mod">
						{advantages_slider.map(({
							title,
							amount,
							text,
							icon,
						}, index) => {
							advantagesClassFunc(index)
							return (
								<AdvantageItem
									title={title}
									icon={icon}
									amount={amount}
									text={text}
									key={index}
									preparedClassName={preparedClassName}
								/>
							)
						})}
					</Slider>
				}
				<div className="btn_wrap">
					<div className="btn_wrap_in">
						<div className="btn_wrap_block">
							<BtnBase
									buttonSettings={cta_button}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>

	);
};

export default HomePageSectionTwo;

HomePageSectionTwo.propTypes = {
	sectionData: PropTypes.object.isRequired
};