import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SectionHead = ({
	title,
	titleH1,
	description,
	offsetV3Mod,
	offsetV4Mod,
	offsetV5Mod,
	offsetV6Mod,
	textColor,
	textColor2
}) => {

	const sectionHeadClasses = classNames('section_head row offset_mod', {
		offset_v3_mod: offsetV3Mod,
		offset_v4_mod: offsetV4Mod,
		offset_v5_mod: offsetV5Mod,
		offset_v6_mod: offsetV6Mod,
	});

	const sectionTitleClasses = classNames('section_title col col_5 offset_1', {
		'color_mod': textColor,
		'color_fade_mod': textColor2,
	});

	const sectionDescrClasses = classNames('section_descr col col_4 offset_2', {
		'color_mod': textColor,
		'color_fade_mod': textColor2,
	})

	return (
		<div className={sectionHeadClasses}>
			{title ? (
					titleH1 === true ? (
							<h1 className={sectionTitleClasses}>{title}</h1>
					) : (
							<h2 className={sectionTitleClasses}>{title}</h2>
					)
			) : null}
			{description ? (
				<div className={sectionDescrClasses} dangerouslySetInnerHTML={{ __html: description }} />
			) : null}
		</div>
	);
};

export default SectionHead;

SectionHead.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	offsetV3Mod: PropTypes.bool,
	offsetV4Mod: PropTypes.bool,
	offsetV5Mod: PropTypes.bool,
	offsetV6Mod: PropTypes.bool,
};

SectionHead.defaultProps = {
  offsetV3Mod: false,
  offsetV4Mod: false,
  offsetV5Mod: false,
  offsetV6Mod: false,
};