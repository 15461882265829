import { api } from 'utils/api';

const getNavMenuItems = async () => {
	// const menuItems = await api.get('/nav/main-menu');
	const menuItems = await api.get('/nav/test-menu');

	return menuItems;
};

const getAllPages = async () => {
	const allPages = await api.get('/wp/v2/pages?per_page=50');

	return allPages;
};

const getAllEvents = async () => {
	const allEvents = await api.get('/wp/v2/events/');

	return allEvents;
};

const getAppBaseData = async () => {
	const appBaseData = await api.get('/acf/v3/options/options');

	return appBaseData;
};

const getAllNews = async () => {
	const allNews = await api.get('/wp/v2/posts?&per_page=100');

	return allNews;
};

const searchByEveryThing = async (searchQuery, pageNumber) => {
	const preparedPageNumber = pageNumber || 1;
	const res = await api.get(`/wp/v2/search?search=${searchQuery}&per_page=100&page=${preparedPageNumber}`);
	// const res = await api.get(`/relevanssi/v1/search?keyword=${searchQuery}`);

	return res;
};

const getAllMapPoints = async () => {
	const res = await api.get('/pop/points');

	return res;
};

export {
	getNavMenuItems,
	getAllPages,
	getAllEvents,
	getAllNews,
	getAppBaseData,
	searchByEveryThing,
	getAllMapPoints,
};