import React from 'react';

import { SOCIAL_LIST } from './constant';

const SocialList = ({ handleOpenPopup }) => {
	return (
		<ul className="social_list">
			{SOCIAL_LIST.map(({ Icon, url, isPopup }, index) => {
				return (
					<li className="social_item" key={index}>
						<a className="social_link" href={url} onClick={isPopup ? handleOpenPopup : null}>
							<Icon className="icon size_mod" />
						</a>
					</li>
				)
			})}
		</ul>
	);
};

export default SocialList;