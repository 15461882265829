import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';

import { SectionBg } from 'components/SectionBg';
import {BtnBase} from "../BtnBase";

const HomePageSectionThree = ({
	sectionData
}) => {
	const {
		section_title,
		section_background_image,
		discover,
		cta_button_text,
		cta_button_url,
		cta_button
	} = sectionData;

	return (
		<>
			<section className="section index_mod offset_mod discoverSection">
				<SectionBg
					url={section_background_image.url}
					alt={section_background_image.alt}
				/>
				<Decors />
				<div className="section_in">
					<SectionHead
						title={section_title}
						offsetV5Mod
						textColor2
					/>
					<div className="discover_cols row">
						{discover.map(({ text }, index) => {
							return (
								<div className="discover_item col col_1 offset_1 no_offset_mod col_tab_1 col_mob_1" key={index}>
									<div className="discover_item_in" dangerouslySetInnerHTML={{ __html: text }} />
								</div>
							)
						})}
					</div>
					<div className="btn_wrap">
						<div className="btn_wrap_in">
							<div className="btn_wrap_block">
								<BtnBase
										buttonSettings={cta_button}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default HomePageSectionThree;

HomePageSectionThree.propTypes = {
	sectionData: PropTypes.object.isRequired
};