import React from 'react';

import { INPUTS } from './constant';
import CheckBox from './CheckBox';
import { ButtonSubmit } from 'components/Buttons';
import { Alert } from 'components/Alert';

const PopupForm = ({
	errors,
	handleBlur,
	handleChange,
	handleSubmit,
	touched,
	values,
	isSubmitting,
	alert,
	hideAlert
}) => {

	return (
		<form className="request" onSubmit={handleSubmit} noValidate>
			<div className="request_inner">
				{INPUTS.map(({
					type,
					id,
					placeholder,
					name,
					required
				}) => {
					return (
						<dl className="form_cell form_cell_v1_mod half_mod" key={id}>
							<dt className="form_cell_title form_v1_mod hline_hide_mod">
								<label htmlFor={id} />
							</dt>
							<dd className="form_field_wrap form_v1_mod hline_hide_mod">
								{type === 'textarea' ? (
									<textarea
										className="form_field default_mod textarea_mod"
										type={type}
										id={id}
										placeholder={placeholder}
										name={name}
										value={values[name]}
										required={required}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
								) : (
										<input
											className="form_field default_mod"
											type={type}
											id={id}
											placeholder={placeholder}
											name={name}
											value={values[name]}
											required={required}
											onBlur={handleBlur}
											onChange={handleChange}
										/>
									)}
								<div className="form_error">{touched[name] && errors[name]}</div>
							</dd>
						</dl>
					)
				})}
			</div>
			<div className="check_box_wrap">
				<CheckBox
					title="Yes, please keep me updated with Avelacom news"
					id="keep_news"
					name="newsSubscribe"
					onChange={handleChange}
					checked={values.newsSubscribe}
				/>
			</div>
			<div className="btn_wrap submit_mod">
				{alert.visible ? (
					<Alert
						alert={alert}
						hide={hideAlert}
					/>
				) : (
						<ButtonSubmit
							buttonText="Submit"
							isDisabled={isSubmitting}
						/>
					)}
			</div>
		</form>
	);
};

export default PopupForm;