import React, { useEffect, useRef, useContext } from 'react';
import { gsap, Power3 } from "gsap";
import { AnimationContext } from 'context/animation';
import { JsonDataContext } from 'context/jsonData';

const Preloader = () => {
	const preloaderProgress = useRef(null);
	const preloader = useRef(null);
	const { isPreloaderAnimDone, setPreloaderAnimDone, setPreloaderProgressAnimDone } = useContext(AnimationContext);
	const { pagesData } = useContext(JsonDataContext);

	useEffect(() => {
		if (preloaderProgress.current) {
			gsap
				.from(preloaderProgress.current, {
					xPercent: -105,
					duration: .1,
					// duration: .2,
					delay: .1,
					ease: Power3.easeOut,
					onComplete: () => setPreloaderAnimDone(true)
				})
		}

	}, []);

	useEffect(() => {
		if (preloader.current && isPreloaderAnimDone && pagesData) {
			gsap
				.to(preloader.current, {
					opacity: 0,
					duration: 3,
					// duration: .3,
					delay: .5,
					ease: Power3.easeOut,
					onComplete: () => setPreloaderProgressAnimDone(true)
				})
		}

	}, [isPreloaderAnimDone, pagesData]);

	return (
		<div className="preloader" ref={preloader}>
			<div className="preloader_progress">
				<div className="preloader_progress_in" ref={preloaderProgress}></div>
			</div>
		</div>
	);

};

export default Preloader;