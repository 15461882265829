import { gsap } from "gsap";

import { SplitText } from "gsap/SplitText";

export const initTimeLines = () => {
	let tlDefaultSettings = {
		paused: true
	};

	const windowWidth = window.innerWidth;

	const createEntrySectionAnim = $section => {
		let subEntryTl = gsap.timeline();

		subEntryTl
			.addLabel('start')
			.to($section, {
				zIndex: 10,
				duration: 0.001
			})
			.fromTo($section, {
				yPercent: 0
			}, {
				yPercent: -100,
				duration: 1,
				ease: "power1.inOut",
				onComplete: () => {
					gsap.to($section, {
						clearProps: 'zIndex'
					})
				}
			}, 'start')

		return subEntryTl;
	};

	const createLeaveAnimTween = $section => {
		let subLeaveTl = gsap.timeline();
		let $sectionBlock = $section.querySelector('.section_in');

		subLeaveTl
			.addLabel('start')
			.to($section, {
				clearProps: 'zIndex'
			})
			.fromTo($sectionBlock, {
				y: 0
			}, {
				y: -150,
				duration: 2,
				zIndex: 5,
				ease: "power1.out",
				immediateRender: false,
			}, 'start')
			.set($section, {
				yPercent: 0,
			}, 'start+=1')
			

		return subLeaveTl;
	};

	const createEntryTitleAnim = $section => {
		let subTitleTl = gsap.timeline();
		let $sectionTitle = $section.querySelector('.section_title');

		let sectionTitleWords = new SplitText($sectionTitle, { type: "words" });

		subTitleTl
			.fromTo(sectionTitleWords.words, {
				x: '-100vw'
			}, {
				x: 0,
				stagger: -.18,
				ease: "power4.out",
				duration: 1.2
			})

		return subTitleTl;
	};

	const createEntryDescrAnim = $section => {
		let subDescrTl = gsap.timeline();
		let $sectionDescr = $section.querySelector('.section_descr');

		subDescrTl
			.fromTo($sectionDescr, {
				y: -30,
				opacity: 0
			}, {
				y: 0,
				opacity: 1,
				ease: "power4.out",
				duration: 1.2
			})

		return subDescrTl;
	};

	const createEntryBtnAnim = $section => {
		let subBtnTl = gsap.timeline();
		let $sectionBtnWrap = $section.querySelector('.btn_wrap_in');
		let $sectionBtn = $section.querySelector('.btn_wrap_block');

		subBtnTl
			.addLabel('start_btn')
			.fromTo([$sectionBtnWrap, $sectionBtn], {
				xPercent: gsap.utils.wrap([-100, 100]),
			}, {
				xPercent: 0,
				duration: 1.2,
				ease: "power1.out",
			})

		return subBtnTl;
	};

	const createEntryProductAnim = $section => {
		let subProductTl = gsap.timeline();
		let $productTitle;
		let $productText;

		if (windowWidth >= 1024) {
			$productTitle = $section.querySelectorAll('.product_title');
			$productText = $section.querySelectorAll('.product_text');
		} else {
			let $allActiveSlides = $section.querySelectorAll('.slick-active');

			$productTitle = [];
			$productText = [];

			$allActiveSlides.forEach(slide => {
				$productTitle.push(slide.querySelector('.product_title'));
				$productText.push(slide.querySelector('.product_text'));
			});
		}

		subProductTl
			.addLabel('start_it')
			.fromTo($productTitle, {
				xPercent: 100,
			}, {
				xPercent: 0,
				duration: .8,
				stagger: .2,
				ease: "power3.out",
			}, 'start_it')
			.fromTo($productText, {
				y: -10,
				opacity: 0
			}, {
				y: 0,
				opacity: 1,
				duration: .7,
				stagger: .2,
				ease: "power4.out",
			}, 'start_it')

		return subProductTl;
	};

	const createAdvantagesItemAnim = () => {
		let advantagesTl = gsap.timeline();
		let advantagesItems = document.querySelectorAll('.advantages_item');
		let preparedAdvantagesItems;

		if (windowWidth > 480) {
			preparedAdvantagesItems = [advantagesItems[0], advantagesItems[2], advantagesItems[1]];
		} else {
			preparedAdvantagesItems = [advantagesItems[1], advantagesItems[3], advantagesItems[2]]
		}

		advantagesTl
			.fromTo(preparedAdvantagesItems, {
				x: -50,
				opacity: 0
			}, {
				x: 0,
				opacity: 1,
				stagger: .5,
				ease: "power2.out",
				duration: 1.2
			})

		return advantagesTl;
	};

	const initHeroTl = () => {
		let $heroSection = document.querySelector('.heroSection');
		let tl = gsap.timeline(tlDefaultSettings);

		tl
			.addLabel('entry_start')
			.add(createEntrySectionAnim($heroSection), 'entry_start')
			.addLabel('entry_end')
			.addLabel('leave_start')
			.add(createLeaveAnimTween($heroSection), 'leave_start')
			.addLabel('leave_end')

		return {
			name: 'hero',
			tl
		};
	};

	const initSpeedSectionTl = () => {
		let $speedSection = document.querySelector('.speedSection');
		let tl = gsap.timeline(tlDefaultSettings);

		tl
			.addLabel('entry_start')
			.add(createEntrySectionAnim($speedSection), 'entry_start')
			.add(createEntryTitleAnim($speedSection), 'entry_start+=.4')
			.add(createEntryDescrAnim($speedSection), 'entry_start+=.8')
			.add(createAdvantagesItemAnim(), 'entry_start+=.8')
			.add(createEntryBtnAnim($speedSection), 'entry_start+=2.1')
			.addLabel('entry_end')
			.addLabel('leave_start')
			.add(createLeaveAnimTween($speedSection), 'leave_start')
			.addLabel('leave_end')

		return {
			name: 'speed',
			tl
		};
	};

	const initDiscoverSectionTl = () => {
		let $discoverSection = document.querySelector('.discoverSection');
		let tl = gsap.timeline(tlDefaultSettings);

		tl
			.addLabel('entry_start')
			.add(createEntrySectionAnim($discoverSection), 'entry_start')
			.add(createEntryTitleAnim($discoverSection), 'entry_start+=.4')
			.fromTo('.discover_item_in', {
				xPercent: 100
			}, {
				xPercent: 0,
				stagger: .3,
				ease: "power1.out",
				duration: .8
			}, 'entry_start+=.8')
			.add(createEntryBtnAnim($discoverSection), '-=.7')
			.addLabel('entry_end')
			.addLabel('leave_start')
			.add(createLeaveAnimTween($discoverSection), 'leave_start')
			.addLabel('leave_end')

		return {
			name: 'discover',
			tl
		};
	};


	const initChooseSectionTl = () => {
		let $chooseSection = document.querySelector('.chooseSection');
		let tl = gsap.timeline(tlDefaultSettings);

		tl
			.addLabel('entry_start')
			.add(createEntrySectionAnim($chooseSection), 'entry_start')
			.add(createEntryTitleAnim($chooseSection), 'entry_start+=.4')
			.add(createEntryDescrAnim($chooseSection), 'entry_start+=.8')
			.add(createEntryProductAnim($chooseSection), 'entry_start+=1')
			.addLabel('entry_end')
			.addLabel('leave_start')
			.add(createLeaveAnimTween($chooseSection), 'leave_start')
			.addLabel('leave_end')

		return {
			name: 'choose',
			tl
		};
	};

	const initCatchUpSectionTl = () => {
		let $catchUpSection = document.querySelector('.catchUpSection');
		let tl = gsap.timeline(tlDefaultSettings);

		tl
			.addLabel('entry_start')
			.add(createEntrySectionAnim($catchUpSection), 'entry_start')
			.add(createEntryTitleAnim($catchUpSection), 'entry_start+=.4')
			.add(createEntryDescrAnim($catchUpSection), 'entry_start+=.8')
			.add(createEntryBtnAnim($catchUpSection), 'entry_start+=1')
			.addLabel('entry_end')
			.addLabel('leave_start')
			.add(createLeaveAnimTween($catchUpSection), 'leave_start')
			.addLabel('leave_end')

		return {
			name: 'choose',
			tl
		};
	};

	let tlHeroSection = initHeroTl();
	let tlSpeedSection = initSpeedSectionTl();
	let tlDiscoverSection = initDiscoverSectionTl();
	let tlChooseSection = initChooseSectionTl();
	let tlCatchUpSection = initCatchUpSectionTl();

	return [
		tlHeroSection,
		tlSpeedSection,
		tlDiscoverSection,
		tlChooseSection,
		tlCatchUpSection
	];

};