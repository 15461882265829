import React from "react";

const SchemaComponent = ({schemaData}) => {
	return (
			<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
			/>
	)
}

export default SchemaComponent;