import React, { useContext, useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';

import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';
import { JsonDataContext } from 'context/jsonData';
import { getPageById } from 'utils';
import {BtnBase} from "../../components/BtnBase";

const AboutUsPage = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 293);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	return (
		currentPageData && (
			<section className="section bg_v1_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<div className="section_in">
					<SectionHead
						title={currentPageData.content[0].title}
						titleH1
						description={currentPageData.content[0].description}
						offsetV3Mod
					/>
					<div className="row offset_mod">
						<div className="section_descr col col_5 offset_1">{ReactHtmlParser(currentPageData.content[1].description)}</div>
					</div>
					<div className="list_block_wrap row col col_5 offset_1">
						<div className="list_block_wrap col col_lg_3 col_tab_2 mobile_offset_mod">
							<h4 className="list_block_title">{currentPageData.content[2].title}</h4>
							<div className="list_block about_page_mod">
								{ReactHtmlParser(currentPageData.content[2].text)}
							</div>
						</div>
						{currentPageData.content[3].cta_button && (
								<BtnBase
										buttonSettings={currentPageData.content[3].cta_button}
										vertCenterMod
										col
										colLg2
										colTab1
										btnSm
								/>
						)}
					</div>
				</div>
			</section>
		)
	);
};

export default AboutUsPage;