import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';
import jsonAppData from 'utils/jsonAppData';

import { Decors } from 'components/Decors';
import { Spinner } from 'components/Spinner';
import { Alert, useAlert } from 'components/Alert';

import { getAllEvents } from 'api/data';
import { BaseLink } from "components/BaseLink";
import {ReactComponent as Twitter} from "../../i/icons/twitter.svg";
import {Link} from "react-router-dom";

const EventsPage = () => {
	const [events, setEvents] = useState(false);
	const [isEventsLoaded, setEventsLoaded] = useState(false);
	const [isFetchError, setFetchError] = useState(false);
	const [alert, showAlert, hideAlert] = useAlert();

	const getAllPageEvents = async () => {
		try {
			const allEvents = await getAllEvents();

			setEvents(allEvents.data);
			setEventsLoaded(true);
		} catch (err) {
			showAlert(err.message, 'danger');
			setEventsLoaded(false);
			setFetchError(true);
		}

	};

	useEffect(() => {
		getAllPageEvents();
	}, []);

	return (
		<section className="section bg_v1_mod">
			{jsonAppData.eventsPage.title && (
				<Helmet>
					<title>{jsonAppData.eventsPage.title}</title>
				</Helmet>
			)}
			<Decors />
			<div className="section_in">
				<div className="section_head row offset_mod">
					<h1 className="section_title col col_5 offset_1">Recent & upcoming events</h1>
					<Link className="section_descr col col_4 offset_2" to="/contact">Let’s catch up! Schedule a meeting with our team.</Link>
				</div>
				{isEventsLoaded ? (
					<div className="event_list row col col_5 offset_1">
						{events.map(({
							title,
							content,
							acf
						}, index) => {
							return (
								<div className="event_item col col_lg_1 col_tab_1 col_mob_3" key={index}>
									{title && title.rendered && (
										<h3 className="event_title">{title.rendered}</h3>
									)}
									<div className="event_descr">
										{acf && acf.event_date && acf.event_date.start_date && (
											<div className="event_descr_item v2_mod">
												Date: {ReactHtmlParser(acf.event_date.start_date)}
												{acf.event_date.end_date && (
													`-${ReactHtmlParser(acf.event_date.end_date)}`
												)}
											</div>
										)}
										{content && content.rendered && (
											<div className="event_descr_item">{ReactHtmlParser(content.rendered)}</div>
										)}
									</div>
									{acf && acf.event_url && (
										<BaseLink
											url={acf.event_url}
											text={acf.button_caption ? acf.button_caption : 'Add to calendar'}
											linkClassName={'event_link'}
										/>

									)}
								</div>
							)
						})}
					</div>
				) : (
					!isFetchError && (
						<Spinner />
					)
				)}
				{alert.visible ? (
					<div className="row col col_5 offset_1">
						<Alert
							alert={alert}
							hide={hideAlert}
						/>
					</div>
				) : null}
			</div>
		</section>

	);
};

export default EventsPage;
