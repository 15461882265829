import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { CSSTransition } from 'react-transition-group';
import { AnimationContext } from 'context/animation';
import PopupClose from "./PopupClose";

import jsonAppData from 'utils/jsonAppData';

const ThanksGetTouchPopup = ({
	isOpen
}) => {
	const { showPopupByKey } = useContext(AnimationContext);

	const handleClosePopup = () => {
		showPopupByKey();
	};

	return (
		<CSSTransition
			in={isOpen}
			timeout={{
				enter: 500,
				exit: 350,
			}}
			classNames="popup"
			mountOnEnter
			unmountOnExit
		>
			<div className="popup">
				<div className="popup_in center_lvl_mod v1_mod">
					<PopupClose onClickHandler={handleClosePopup} />
					<div className="popup_message_w">
						<h2 className="section_title v1_mod center_mod">{ReactHtmlParser(jsonAppData.thanksGettingTouchPopup.message)}</h2>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

export default ThanksGetTouchPopup;