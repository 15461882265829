import React, { useEffect, useContext, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';

import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';
import { BtnBase } from 'components/BtnBase';

import { JsonDataContext } from 'context/jsonData';
import { getPageById } from 'utils';

const GlobalMarkets = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 356);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	const pageContent = currentPageData && currentPageData.content;

	return (
		currentPageData && (
			<section className="section bg_v4_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<div className="section_in">
					<SectionHead
						title={pageContent[0].title}
						titleH1
						description={pageContent[0].description}
					/>
					<h2 className="section_title size_mod center_mod offset_mod col col_5 offset_1">{pageContent[1].title}</h2>
					<div className="global_stats_list row col col_5 offset_1 offset_mod">
						{pageContent[2].text_block.map((item, index) => {
							return (
								<div className="global_stats_item col col_lg_1 no_offset_in_mod col_tab_1" key={index}>
									{ReactHtmlParser(item.text)}
								</div>
							)
						})}
					</div>
					{pageContent[3].cta_button && (
							<BtnBase
									buttonSettings={pageContent[3].cta_button}
									centerMod
							/>
					)}
				</div>
			</section>
		)
	)
}

export default GlobalMarkets;