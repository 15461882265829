import React, { useEffect, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';

import { JsonDataContext } from 'context/jsonData';
import { getPageById } from 'utils';
import { BtnBase } from 'components/BtnBase';

const DataCenterPage = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 351);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();

		}
	}, [pagesData]);

	const pageContent = currentPageData && currentPageData.content;


	return (
		currentPageData && (
			<section className="section bg_v3_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<div className="section_in">
					<SectionHead
						title={pageContent[0].title}
						titleH1
						description={pageContent[0].description}
						offsetV3Mod
					/>
					<div className="product_list row col col_5 offset_1">
						{pageContent[1].text_block.map(({
							text,
						}, index) => {

							if (text, index) {
								return (
									<div className={`product_item col col_lg_1 col_tab_1 col_mob_3 ${index == 0 ? 'offset_lg_1' : ''}`} dangerouslySetInnerHTML={{ __html: text }} key={`product_item_${index}`}/>
								)
							}
						})}
					</div>
					<BtnBase
						buttonSettings={pageContent[2].cta_button}
						offsetMod
					/>
				</div>
			</section>
		)
	);
};

export default DataCenterPage;