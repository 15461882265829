import React, { useContext } from 'react';

import { api } from 'utils/api';
import { withFormik } from 'formik';
import { useAlert } from 'components/Alert';
import { AnimationContext } from 'context/animation';

import JoinForm from './JoinForm';

const FormJoinFormik = ({
	initialValues,
	validate
}) => {
	const [alert, showAlert, hideAlert] = useAlert();
	const { showPopupByKey } = useContext(AnimationContext);

	const Form = withFormik({
		mapPropsToValues: () => {
			return {
				...initialValues
			};
		},

		validate: (values) =>
			Object.keys(values).reduce((errors, field) => {
				const error = validate[field](values[field]);
				return {
					...errors,
					...(error && { [field]: error })
				};
			}, {}),

		handleSubmit: async (values, { setSubmitting, resetForm }) => {
			let formData = new FormData();
			const hideAlertDuration = 4000;

			setSubmitting(true);

			Object.keys(values).forEach(key => {
				formData.set(key, values[key]);
			});

			formData.set('subject', values.subjectEmail);

			try {
				const sendingRes = await api.post('/contact-form-7/v1/contact-forms/609/feedback', formData, {
					headers: {
						"content-type": "multipart/form-data",
					},
				});

				if (sendingRes.data.status === 'validation_failed') {
					showAlert(sendingRes.data.message, 'danger');
					setSubmitting(false);

					setTimeout(() => {
						hideAlert();
					}, hideAlertDuration);

				} else {
					// showAlert(sendingRes.data.message, 'success');
					showPopupByKey('signingUp');
					setSubmitting(false);
					resetForm();

					setTimeout(() => {
						hideAlert();
						showPopupByKey();
					}, hideAlertDuration);
				}

			} catch (error) {
				setSubmitting(false);
				showAlert(error, 'danger');
			}
		},

		validateOnChange: false,

		displayName: "FormFormik"
	})(JoinForm);


	return <Form alert={alert} hideAlert={hideAlert} />

};

export default FormJoinFormik;