import React from "react";
import { ReactComponent as CloseIcon } from "i/icons/close_icon.svg";

const PopupClose = ({ onClickHandler }) => {
	return (
		<div className="popup_close icon_mod" onClick={onClickHandler}>
			<CloseIcon className="popup_close_icon color_mod" />
		</div>
	)
}

export default PopupClose;