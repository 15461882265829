import React, { useEffect, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';

import { JsonDataContext } from 'context/jsonData';
import { getPageById } from 'utils';
import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';
import { SolutionTableItem } from 'components/SolutionTable';
import { BtnBase } from 'components/BtnBase';

const Solution6 = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 252);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	const checkTableItemType = type => {
		if (type === 'check') {
			return true;
		}

		return false;
	};

	const checkTableItemText = text => {
		if (text) {
			return text;
		}

		return false;
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	const pageContent = currentPageData && currentPageData.content;

	return (
		currentPageData && (
			<section className="section bg_v2_mod offset_bottom_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<div className="section_in">
					<SectionHead
						title={pageContent[0].title}
						description={pageContent[0].description}
					/>
					<div className="solution_table row col col_5 offset_1">
						<div className="solution_table_in">
							<div className="solution_table_row">
								<SolutionTableItem
									title="Service features"
								/>
								<SolutionTableItem
									title="Colocation"
								/>
								<SolutionTableItem
									title="Dedicated hosting"
								/>
								<SolutionTableItem
									title="Cloud (shared) hosting"
								/>
							</div>
							{pageContent[1].table.map((item, index) => {
								const { features, colocation, dedicated, cloud } = item;

								return (
									<div className="solution_table_row">
										<SolutionTableItem
											title={checkTableItemText(features.text)}
											icon={checkTableItemType(features.type)}
										/>
										<SolutionTableItem
											title={checkTableItemText(dedicated.text)}
											icon={checkTableItemType(dedicated.type)}
										/>
										<SolutionTableItem
											title={checkTableItemText(colocation.text)}
											icon={checkTableItemType(colocation.type)}
										/>
										<SolutionTableItem
											title={checkTableItemText(cloud.text)}
											icon={checkTableItemType(cloud.type)}
										/>
									</div>
								)
							})}
						</div>
					</div>
					<BtnBase
						buttonSettings={pageContent[2].cta_button}
						centerMod
						btnSm
					/>
				</div>
			</section>
		)
	)
}

export default Solution6;