import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { gsap } from "gsap";

import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';
import { SectionBg } from 'components/SectionBg';
import {BtnBase} from "../BtnBase";

const HomePageSectionFive = ({
	sectionData,
	activeId,
}) => {

	const {
		section_title,
		section_background_image,
		section_description,
		cta_button_text,
		cta_button_url,
		cta_button,
	} = sectionData;

	let $bgAnim = document.querySelector('.animBg');
	let tlBgAnim = gsap.timeline({
		paused: true,
		yoyo: true,
		repeat: -1
	})

	tlBgAnim
		.fromTo($bgAnim, {
			rotate: 0
		},
		{
			rotate: 45,
			duration: 15,
			ease: "power1.inOut",
		})

	useEffect(() => {
		if (activeId == 4) {
			tlBgAnim.play()
		} else if (tlBgAnim.progress(0)) {
			tlBgAnim.pause();
		}
	}, [activeId]);

	return (
		<section className="section index_mod catchUpSection">
			<SectionBg
				mod="animBg"
				url={section_background_image.url}
				alt={section_background_image.alt}
			/>
			<Decors />
			<div className="section_in">
				<SectionHead
					title={section_title}
					description={section_description}
					offsetV4Mod
					textColor2
				/>
				<div className="btn_wrap">
					<div className="btn_wrap_in">
					<div className="btn_wrap_block">
						<BtnBase
								buttonSettings={cta_button}
						/>
					</div> 
					</div>
				</div>
			</div>
		</section>

	);
};

export default HomePageSectionFive;

HomePageSectionFive.propTypes = {
	sectionData: PropTypes.object.isRequired
};