import React, { useEffect, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';
import { JsonDataContext } from 'context/jsonData';
import { getPageById } from 'utils';
import { BtnBase } from 'components/BtnBase';

const Solution8 = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 339);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	const pageContent = currentPageData && currentPageData.content;

	return (
		currentPageData && (
			<section className="section bg_v2_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<div className="section_in">
					<SectionHead
						title={pageContent[0].title}
						description={pageContent[0].description}
					/>
					<div className="solution_region_list_wrap">
						<div className="solution_region_list">
							{pageContent[1] && pageContent[1].images && pageContent[1].images.map((item) => {
								return (
									<div className="solution_region">
										<div className="solution_region_in">
											{item.image && (
												<div className="solution_region_img_wrap">
													<img className="solution_region_img" src={item.image} />
												</div>
											)}
											{item.title && (
												<div className="solution_region_title">{item.title}</div>
											)}
										</div>
									</div>
								)
							})}
						</div>
					</div>
					<BtnBase
						buttonSettings={pageContent[2].cta_button}
						centerMod
					/>
				</div>
			</section>
		)
	)
}

export default Solution8;