import React from 'react';
import Scrollbar from 'react-scrollbars-custom';

const MapCityList = ({
	headingTitle,
	headingPlaceholder,
	cityList,
	selectedCity,
	setSelectedCity,
}) => {

	const filterCityList = cityId => {
		if (cityId) {
			const newSelectedCity = cityList.filter(({
				id,
			}) => {
				if (id === cityId) return true;
			});

			setSelectedCity(...newSelectedCity);
		}
	};

	return (
		<div className="map_location_col">
			<div className="map_location_title">{headingTitle}</div>
			<div className="map_location_placeholder">{headingPlaceholder}</div>
			{cityList && (
				<div className="map_location_list lg_mod">
					<Scrollbar>
						{cityList.map(({
							id,
							name,
						}, index) => {
							let isActive;

							if (selectedCity) {
								isActive = selectedCity.id === id ? 'active_state' : '';
							}

							return (
								<div className="map_location_item" key={index}>
									<button
										className={`map_location_button ${isActive}`}
										onClick={() => filterCityList(id)}
									>
										{name}
									</button>
								</div>
							)
						})}
					</Scrollbar>
				</div>
			)}
		</div>
	);
};

export default MapCityList;