export const INPUTS = [
	{
		type: "text",
		id: "fullname-input",
		placeholder: "NAME*",
		name: "fullName",
		required: true
	},
	{
		type: "text",
		id: "company-input",
		placeholder: "COMPANY",
		name: "company",
		required: false,
	},
	{
		type: "email",
		id: "email-input",
		placeholder: "Email*",
		name: "email",
		required: true
	},
	{
		type: "text",
		id: "phone-input",
		placeholder: "Phone",
		name: "phone",
		required: false
	},
	{
		type: "text",
		id: "location-input",
		placeholder: "Country",
		name: "companyLocation",
		required: false
	},
	{
		type: "textarea",
		id: "help-input",
		placeholder: "How Can we Help?",
		name: "help",
		required: false
	},
];

export const JOIN_FORM_INPUTS = [
	{
		type: "text",
		id: "user_name",
		placeholder: "NAME*",
		name: "fullName",
		required: true
	},
	{
		type: "email",
		id: "user_email",
		placeholder: "Email*",
		name: "email",
		required: true
	},
];

export const REQUEST_FORM_INPUTS = [
	{
		type: "text",
		id: "user_name",
		placeholder: "NAME*",
		name: "fullName",
		required: true
	},
	{
		type: "email",
		id: "user_email",
		placeholder: "Email*",
		name: "email",
		required: true
	},
	{
		type: "text",
		id: "user_company",
		placeholder: "Company",
		name: "company",
		required: false
	},
	{
		type: "select",
		id: "select_options",
		placeholder: "I and interested in",
		name: "interested",
		required: false,
		options: [
			{
				value: 'latency_connectivity',
				label: 'latency connectivity'
			},
			{
				value: 'colocation',
				label: 'Colocation'
			},
			{
				value: 'servers_equipment',
				label: 'SERVERS & EQUIPMENT'
			},
			{
				value: 'сlouds',
				label: 'Clouds'
			},
			{
				value: 'other',
				label: 'other'
			}
		]
	},
];