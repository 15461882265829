import React from 'react';

const CheckBox = ({
	title,
	id,
	name,
	checked,
	onChange
}) => {
	return (
		<label className="radio_check check_1_mod">
			<input
				className="radio_check_input"
				type="checkbox"
				id={id}
				name={name}
				onChange={onChange}
				checked={checked}
			/>
			<span className="radio_check_text check_1_mod">{title}</span>
		</label>
	);
};

export default CheckBox;