import React, { useState, useEffect, useRef, useContext } from 'react';
import { gsap } from "gsap";
import { NavLink, useLocation } from 'react-router-dom';
import { TABLET_MEDIA_POINT } from 'utils/constant';
import { AnimationContext } from 'context/animation';
import SubMenuList from './SubMenuList';
import { getType } from 'utils';
import { JsonDataContext } from 'context/jsonData';

const MenuListItem = ({
	title,
	url,
	subMenu,
	id,
	isSubItemVisible,
	setSubItemVisible,
}) => {
	const { windowWidth, bodyEl, toggleMainMenuState, mainMenuOpen, isMobile } = useContext(AnimationContext);
	const { dropDownActiveMenuItem, mainMenuActiveItemId, setMainMenuActiveItemId, setDropDownActiveMenuItem } = useContext(JsonDataContext);
	const location = useLocation();
	const mainMenuItemEl = useRef();
	const singleLinkEl = useRef();
	const [tlMenuAnim, setTlMenuAnim] = useState(null);

	const isSubMenuExist = () => {
		let isExist = false;

		if(subMenu && subMenu.length) isExist = true;

		return isExist;
	};

	useEffect(() => {
		if (isSubMenuExist() ) {
			const tl = gsap.timeline({
				paused: true
			});

			const subMenuItems = mainMenuItemEl.current.querySelectorAll('.main_menu_subitem_w');

			tl
				.addLabel('start')
				.fromTo(subMenuItems, {
					yPercent: function (i) {
						return -100 * (i + 1)
					},
					opacity: 0,
					pointerEvents: "none"
				}, {
					opacity: 1,
					pointerEvents: "inherit",
					yPercent: 0,
					stagger: {
						from: "end",
						amount: .6
					},
					ease: "none",
				})

				tl.timeScale(2);

			if (windowWidth >= TABLET_MEDIA_POINT) {
				tl
					.fromTo(mainMenuItemEl.current, {
						pointerEvents: 'none'
					}, {
						pointerEvents: 'inherit'
					}, 'start')
			}

			setTlMenuAnim(tl);
		}

	}, [subMenu, isMobile]);

	const onMouseEnter = (e) => {
		if (isSubMenuExist() && windowWidth >= TABLET_MEDIA_POINT && tlMenuAnim) {
			if (bodyEl.current) {
				bodyEl.current.classList.add('menu_open');
			}
			tlMenuAnim.play();
			setSubItemVisible(false);
		} else {
			if (e.target.classList.contains('single_mod')) {
				if (bodyEl.current) {
					bodyEl.current.classList.add('menu_open');
				}
			}
		}
	};

	const onMouseLeave = (e) => {
		if (isSubMenuExist() && windowWidth >= TABLET_MEDIA_POINT && tlMenuAnim) {
			if (bodyEl.current) {
				bodyEl.current.classList.remove('menu_open');
			}
			setSubItemVisible(true);
			tlMenuAnim.reverse();
		} else {
			if (e.target.classList.contains('single_mod')) {
				if (bodyEl.current) {
					bodyEl.current.classList.remove('menu_open');
				}
			}
		}
	};

	const handleOnClick = () => {

		if (windowWidth < TABLET_MEDIA_POINT && isSubMenuExist()) {
			setMainMenuActiveItemId(id);
		}

		if(!isSubMenuExist()) {
			setMainMenuActiveItemId(id);
			setDropDownActiveMenuItem({
				rootMenuItemId: -1
			});
		}
	};

	useEffect(() => {
		if (windowWidth < TABLET_MEDIA_POINT) {
			if (mainMenuActiveItemId === id) {
				if (isSubMenuExist() && tlMenuAnim) {
					if (tlMenuAnim.progress() === 1) {
						tlMenuAnim.reverse();
					} else {
						tlMenuAnim.restart();
					}
				} else {
					if (mainMenuOpen) {
						toggleMainMenuState();
						setDropDownActiveMenuItem({
							rootMenuItemId: -1
						});
					}
				}
			} else {
				if (tlMenuAnim) {
					tlMenuAnim.reverse();
				}
			}
		}
	}, [mainMenuActiveItemId]);

	useEffect(() => {
		if (location && location.pathname === url) {
			setMainMenuActiveItemId(id);
		}
	}, [location]);

	const activeMod = mainMenuActiveItemId === id ? 'active_mod' : '';


	return (
		<li
			className={`main_menu_item ${activeMod}`}
			ref={mainMenuItemEl}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<span className={`main_menu_link_decor ${activeMod}`}></span>
			<div className="main_menu_item_in">
				{isSubMenuExist() ? (
					<button className={`main_menu_link ${activeMod}`} onClick={handleOnClick}>{title}</button>
				) : (
						url && title && (
							<NavLink
								className={`main_menu_link single_mod ${activeMod}`}
								to={url}
								exact
								onClick={(e) => handleOnClick(e, url)}
								ref={singleLinkEl}
							>
								{title}
							</NavLink>
						)
					)}
				{dropDownActiveMenuItem && dropDownActiveMenuItem.rootMenuItemId === id && isSubItemVisible && (
					<div className="main_menu_subtitle">{dropDownActiveMenuItem.title}</div>
				)}
			</div>
			{subMenu && subMenu.length ? (
				<SubMenuList
					rootMenuItemId={id}
					title={title}
					menuList={subMenu}
					tlMenuAnim={tlMenuAnim}
				/>
			) : null}
		</li>
	);
};

export default MenuListItem;