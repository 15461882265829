import React from 'react';
import { hydrate, render } from "react-dom";
import './styles/main_global.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { JsonDataState } from 'context/jsonData';
import { AnimationState } from 'context/animation';
import ReactGA from 'react-ga';

render(
	<React.StrictMode>
		<Router>
			<JsonDataState>
				<AnimationState>
					<App />
				</AnimationState>
			</JsonDataState>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
	hydrate(
		<React.StrictMode>
			<Router>
				<JsonDataState>
					<AnimationState>
						<App />
					</AnimationState>
				</JsonDataState>
			</Router>
		</React.StrictMode>
		, rootElement);
} else {
	render(
		<React.StrictMode>
			<Router>
				<JsonDataState>
					<AnimationState>
						<App />
					</AnimationState>
				</JsonDataState>
			</Router>
		</React.StrictMode>
		, rootElement);
}

ReactGA.initialize('UA-76447239-1');
ReactGA.pageview(window.location.pathname + window.location.search);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
