import React, { useEffect, useContext, useState, useRef } from 'react';

import { Decors } from 'components/Decors';
import { Helmet } from 'react-helmet';

import { JsonDataContext } from 'context/jsonData';
import { getPageById } from 'utils';
import { Alert, useAlert } from 'components/Alert';
import { Spinner } from 'components/Spinner';
import { MapRadioControl, MapContainer } from 'components/Map';
import { getAllMapPoints } from 'api/data';

import {
	FILTER_OPTIONS,
} from './constant';

const GlobalLocations = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [isFetchError, setFetchError] = useState(false);
	const [pageTitle, setPageTitle] = useState(null);
	
	const [allMapData, setAllMapData] = useState(null);
	const [mapPoints, setMapPoints] = useState(null);
	const [isFilterReset, setIsFilterReset] = useState(false);
	const [filteredMapPoints, setFilteredPoints] = useState(null);
	const [isMapPointsLoaded, setMapPointsLoaded] = useState(false);
	const [selectedRegionId, setSelectedRegionId] = useState(0);
	const [alert, showAlert, hideAlert] = useAlert();
	const [mapCheckboxState, setMapCheckboxState] = useState({
		tradingSpot: false,
		pointPresence: true,
	});

	const [filterOptions, setFilterOptions] = useState(FILTER_OPTIONS);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 333);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	const fetchAllMapPoints = async () => {
		try {
			const preparedAllNews = await getAllMapPoints();

			setAllMapData(preparedAllNews.data);
			setMapPoints(preparedAllNews.data.points);
			setFilteredPoints(preparedAllNews.data.points);
			setMapPointsLoaded(true);
		} catch (err) {
			showAlert(err.message, 'danger');
			setMapPointsLoaded(false);
			setFetchError(true);
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	useEffect(() => {
		fetchAllMapPoints();
	}, []);

	const handleRegionClick = regionId => {
		if (regionId !== undefined) {
			setFilterOptions({
				...filterOptions,
				region: regionId
			});
			setSelectedRegionId(regionId);
		}
	};

	const filterMapPoints = () => {

		let checkIsFilterMatch = (keyName, id) => {
			let isMatch = true;

			if (filterOptions[keyName] !== 0) {
				isMatch = id === filterOptions[keyName];
			}

			return isMatch;
		};

		const updatedFilteredMapPoints = mapPoints.filter(({
			city,
			country,
			region,
			trading_venue,
		}) => {
			let isRegionIdMatch = checkIsFilterMatch('region', region.id);
			let isCountryIdMatch = checkIsFilterMatch('country', country.id);
			let isCityIdMatch = checkIsFilterMatch('city', city.id);
			let isTradingVenue;

			if (mapCheckboxState.tradingSpot) {
				isTradingVenue = trading_venue === mapCheckboxState.tradingSpot;
			} else {
				isTradingVenue = true;
			}

			if (isCountryIdMatch && isCityIdMatch && isRegionIdMatch && isTradingVenue) return true;
		});

		setFilteredPoints(updatedFilteredMapPoints);
	};

	const resetFilter = () => {
		setIsFilterReset(true)
		setFilterOptions(FILTER_OPTIONS)

		setFilteredPoints([...allMapData.points])
		setSelectedRegionId(0);
		setMapCheckboxState({
			tradingSpot: false,
			pointPresence: true,
		});
	};

	useEffect(() => {
		if (isMapPointsLoaded) {
			setFilterOptions({
				...filterOptions,
				trading: mapCheckboxState.pointPresence
			});
		}
	}, [mapCheckboxState]);

	useEffect(() => {
		if (isMapPointsLoaded && !isFilterReset) {
			filterMapPoints();
		}
	}, [filterOptions]);

	useEffect(() => {
		if (filteredMapPoints && isFilterReset) {
			setIsFilterReset(false)
		}
	}, [filteredMapPoints]);

	return (
		currentPageData && (
			<section className="section offset_top_mod bg_v4_mod offset_bottom_2_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<div className="section_in">
					<div className="tab_content_wrap row col col_6 offset_1">
						<h1 className="section_title size_v2_mod offset_v2_mod">PoP locations</h1>
						<div className="map_control">
							<button className="map_control_reset" onClick={() => {resetFilter()}}>Reset all filters</button>
							<div className="map_control_in">
								<MapRadioControl
									id="tradingSpot"
									title="Trading spot"
									inputName="tradingSpot"
									checked={mapCheckboxState.tradingSpot}
									mapCheckboxState={mapCheckboxState}
									setMapCheckboxState={setMapCheckboxState}
									/>
								<MapRadioControl
									id="pointPresence"
									title="Point of Presence"
									inputName="pointPresence"
									checked={mapCheckboxState.pointPresence}
									mapCheckboxState={mapCheckboxState}
									setMapCheckboxState={setMapCheckboxState}
									/>
							</div>
						</div>
						{isMapPointsLoaded && allMapData ? (
							<MapContainer
								mapPoints={filteredMapPoints}
								mapData={allMapData}
								filterOptions={filterOptions}
								isFilterReset={isFilterReset}
								setFilterOptions={setFilterOptions}
							/>
						) : (
							!isFetchError && (
								<Spinner />
							)
						)}
						{alert.visible ? (
							<div className="row col col_5 offset_1">
								<Alert
									alert={alert}
									hide={hideAlert}
								/>
							</div>
						) : null}
					</div>
					{allMapData && allMapData.regions && (
						<div className="tab_links_wrap row col col_5 offset_1">
							{allMapData.regions.map(({ name, id }, index) => {
								let isActive = id === selectedRegionId ? 'active_tab' : '';

								return (
									<div
										className={`tab_link map_mod col col_lg_1 ${isActive}`}
										key={index}
										onClick={() => handleRegionClick(id)}
									>
										{name}
									</div>
								)
							})}
						</div>
					)}
				</div>
			</section>
		)
	)
}

export default GlobalLocations;