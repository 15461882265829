import React from 'react';
import { Route, Redirect } from "react-router-dom";

const RedirectRoute = ({ component: RouteComponent, ...rest }) => {
	return (
		<Route
			{...rest}
			render={routeProps => <Redirect to="/" />}
		/>
	);

};

export default RedirectRoute;