import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import APP_DATA from 'utils/jsonAppData';
import { AnimationContext } from 'context/animation';

// REFACTOR
import { FormJoinFormik } from 'components/Form';

import { nameValidation, emailValidation } from 'utils';

import { ReactComponent as CloseIcon } from 'i/icons/close_icon.svg';

const JoinPopup = ({
	isOpen,
}) => {
	const { showPopupByKey } = useContext(AnimationContext);

	const validate = {
		fullName: name => nameValidation("Name", name),
		email: emailValidation,
		subjectEmail: check => { return null },
	};

	const initialValues = {
		fullName: '',
		email: '',
		subjectEmail: APP_DATA.signUpNewsForm.subjectEmail
	};

	const handleClosePopup = () => {
		showPopupByKey();
	};

	return (
		<CSSTransition
			in={isOpen}
			timeout={{
				enter: 500,
				exit: 350,
			}}
			classNames="popup"
			mountOnEnter
			unmountOnExit
		>
			<div className="popup v2_mod join_mod">
				<div className="overlay" onClick={handleClosePopup} />
				<div className="popup_in">
					<div className="popup_in_content">
						<div className="popup_close icon_mod" onClick={handleClosePopup}>
							<CloseIcon className="popup_close_icon color_mod" />
						</div>
						<div className="popup_title_wrap">
							<div className="popup_title">SIGN UP FOR NEWS</div>
							<div className="popup_descr">Keep me updated on key developments</div>
						</div>
						<FormJoinFormik validate={validate} initialValues={initialValues} />
					</div>
				</div>
			</div>
		</CSSTransition>
	)
};

export default JoinPopup;

// JoinPopup.propTypes = {
// 	handleOpenPopup: PropTypes.func.isRequired,
// };