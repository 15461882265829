import React, { useState, useEffect, useContext, useRef } from 'react';
import Slider from "react-slick";
import { useSwipeable } from "react-swipeable";
import { debounce } from 'utils';
import { Helmet } from 'react-helmet';
import jsonAppData from 'utils/jsonAppData';
import { gsap } from "gsap";
import Draggable from "gsap/Draggable";

import { JsonDataContext } from 'context/jsonData';
import { AnimationContext } from 'context/animation';
import { TABLET_MEDIA_POINT } from 'utils/constant';

import { Decors } from 'components/Decors';
import { Spinner } from 'components/Spinner';
import { Alert, useAlert } from 'components/Alert';
import { useHistory } from "react-router-dom";

import { getAllNews } from 'api/data';

import { ReactComponent as PrevArrow } from "i/icons/left_arrow.svg";
import { ReactComponent as NextArrow } from "i/icons/right_arrow.svg";

let moment = require("moment");

gsap.registerPlugin(Draggable);

const NewsPage = () => {
	const { allNews, setAllNews } = useContext(JsonDataContext);
	const { windowWidth, newsSliderCurrentSlide, setNewsSliderCurrentSlide, prevLocation } = useContext(AnimationContext);
	const [isNewsLoaded, setNewsLoaded] = useState(false);
	const [isFetchError, setFetchError] = useState(false);
	const [alert, showAlert, hideAlert] = useAlert();
	const history = useHistory();
	const newsSliderEl = useRef();

	const getAllNewsPage = async () => {
		try {
			const preparedAllNews = await getAllNews();

			setAllNews(preparedAllNews.data);
			setNewsLoaded(true);
		} catch (err) {
			showAlert(err.message, 'danger');
			setNewsLoaded(false);
			setFetchError(true);
		}
	};

	const handleOnClick = slug => {
		history.push(`/news/${slug}`);
	};

	const slidePrevBtn = useRef();
	const slideNextBtn = useRef();

	const handleSlickPrev = () => {
		isDraggableActive.current = false;
		newsSliderEl.current.slickPrev();
	};

	const handleSlickNext = () => {
		isDraggableActive.current = false;
		newsSliderEl.current.slickNext();
	};

	const handlers = useSwipeable({
		onSwipedLeft: () => handleSlickNext(),
		onSwipedRight: () => handleSlickPrev(),
		preventDefaultTouchmoveEvent: true,
		trackTouch: true,
	});

	const handleScroll = (e) => {
		if (newsSliderEl.current) {
			if (e.deltaY < 0 || e.deltaX < 0) {
				handleSlickPrev();
			} else if (e.deltaY > 0 || e.deltaX > 0) {
				handleSlickNext();
			}

		}
	}

	let slideCurrentIndex = useRef(0);
	let slideNextIndex = useRef(0)

	const [prevVisible, setPrevVisible] = useState(false);
	const [nextVisible, setNextVisible] = useState(false);

	const sliderSettings = {
		infinite: false,
		slidesToShow: 2,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		dotsClass: 'dots_v2',
		draggable: false,
		touchMove: false,
		swipe: false,
		// autoplaySpeed: 0,
		cssEase: 'linear',
		// speed: 800,
		beforeChange: (current, next) => {
			slideCurrentIndex.current = current;
			slideNextIndex.current = next;
			setNewsSliderCurrentSlide(next);

			if (!isDraggableActive.current) {
				gsap.to($draggableThumb.current, {
					x: (draggableTrackWidth.current / (allNews.length - 1)) * slideNextIndex.current,
					ease: 'none',
				})
			}

			if (slideNextIndex.current >= 1 && !prevVisible) {
				slidePrevBtn.current.classList.remove('hidden_mod');
				setPrevVisible(true);
			} else if (slideNextIndex.current < 1 && prevVisible) {
				slidePrevBtn.current.classList.add('hidden_mod');
				setPrevVisible(false);
			} else if (slideNextIndex.current === allNews.length - 2 && !nextVisible && windowWidth >= 1024) {
				slideNextBtn.current.classList.add('hidden_mod');
				setNextVisible(true);
			} else if (slideNextIndex.current < allNews.length - 2 && nextVisible && windowWidth >= 1024) {
				slideNextBtn.current.classList.remove('hidden_mod');
				setNextVisible(false);
			} else if (slideNextIndex.current === allNews.length - 1 && !nextVisible && windowWidth < 1024) {
				slideNextBtn.current.classList.add('hidden_mod');
				setNextVisible(true);
			} else if (slideNextIndex.current < allNews.length - 1 && nextVisible && windowWidth < 1024) {
				slideNextBtn.current.classList.remove('hidden_mod');
				setNextVisible(false);
			}
		},
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	};

	// slick draggable func
	const pointsLength = useRef(0);
	const $draggableTrack = useRef(null);
	const $draggableThumb = useRef(null);
	let draggableTrackWidth = useRef(0);
	let pointsPositionWidth = useRef(0);
	let points = useRef(0);
	const [indexPosition, setIndexPosition] = useState(0);
	let isDraggableActive = useRef(false)


	const sliderDraggableFunc = () => {

		pointsLength.current = allNews.length - 1;
		draggableTrackWidth.current = $draggableTrack.current.offsetWidth;
		pointsPositionWidth.current = draggableTrackWidth.current / (pointsLength.current);
		$draggableThumb.current.style.width = pointsPositionWidth.current / 10 + 'rem';

		const draggable = Draggable.create($draggableThumb.current, {
			bounds: $draggableTrack.current,
			type: 'x',
			edgeResistance: 1,
			liveSnap: {
				x: (value) => {
					points.current = Math.round(value / pointsPositionWidth.current) * pointsPositionWidth.current;
					return points.current;
				}
			},
			onPress: () => {
				isDraggableActive.current = true;
			},
			onRelease: () => {
				setIndexPosition(Math.round(draggable[0].x / pointsPositionWidth.current));
			},
		});
	}


	useEffect(() => {
		if (windowWidth >= TABLET_MEDIA_POINT) {
			window.addEventListener('wheel', debounce(handleScroll, 35, true));
		}

		if (!allNews.length) {
			getAllNewsPage();
		} else {
			setNewsLoaded(true);
		}

		return () => {
			if (windowWidth >= TABLET_MEDIA_POINT) {
				window.removeEventListener('wheel', debounce);
			}
		}
	}, []);

	useEffect(() => {
		if (isNewsLoaded && newsSliderEl.current && prevLocation) {
			if (prevLocation.includes('news')) {
				newsSliderEl.current.slickGoTo(newsSliderCurrentSlide);
			} else {
				newsSliderEl.current.slickGoTo(0);
				setNewsSliderCurrentSlide(0);
			}
		}
	}, [isNewsLoaded]);

	useEffect(() => {
		if (isNewsLoaded && allNews.length) {
			sliderDraggableFunc();
		}
	}, [isNewsLoaded]);

	useEffect(() => {
		if (isNewsLoaded && allNews.length) {
			newsSliderEl.current.slickGoTo(indexPosition);
		}
	}, [indexPosition]);

	return (
		<section className="section bg_v1_mod offset_bottom_mod">
			{jsonAppData.newsPage.title && (
				<Helmet>
					<title>{jsonAppData.newsPage.title}</title>
				</Helmet>
			)}
			<Decors />
			<div className="section_in">
				<div className="section_head row offset_v3_mod">
					<h1 className="section_title col col_5 offset_1">News</h1>
					<div className="section_descr col col_4 offset_2">Be first to know our latest news</div>
				</div>
				{isNewsLoaded ? (
					<div className="news_preview_wrap row col col_6 offset_1" {...handlers}>
						<div className="news_preview_arrows_w">
							<span className="news_preview_arrow prev_mod hidden_mod" ref={slidePrevBtn} onClick={handleSlickPrev}>
								<PrevArrow className="news_preview_arrow_icon" />
							</span>
							<span className="news_preview_arrow next_mod" ref={slideNextBtn} onClick={handleSlickNext}>
								<NextArrow className="news_preview_arrow_icon" />
							</span>
						</div>
						<Slider {...sliderSettings} className="news_preview_slider" ref={newsSliderEl}>
							{allNews.map(({ date, title, excerpt, acf, slug, id }) => {
								return (
									<div className="news_preview" key={id}>
										<div className="news_preview_in" onClick={() => handleOnClick(slug)}>
											<div className="news_preview_img_wrap">
												<img className="news_preview_img" src={acf.preview_image} alt={title.rendered} />
											</div>
											<div className="news_preview_date">{moment(date).format('LL')}</div>
											<div className="news_preview_title" dangerouslySetInnerHTML={{ __html: title.rendered }} />
											<div className="news_preview_text" dangerouslySetInnerHTML={{ __html: excerpt.rendered }} />
										</div>
									</div>
								)
							})}
						</Slider>
						<div className="news_preview_draggable_w">
							<div className="news_preview_draggable_track" ref={$draggableTrack}>
								<div className="news_preview_draggable_thumb" ref={$draggableThumb}></div>
							</div>
						</div>
					</div>
				) : (
					!isFetchError && (
						<Spinner />
					)
				)}
				{alert.visible ? (
					<div className="row col col_5 offset_1">
						<Alert
							alert={alert}
							hide={hideAlert}
						/>
					</div>
				) : null}
			</div>
		</section>

	);
};

export default NewsPage;
