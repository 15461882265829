import React, { useEffect, useContext, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';

import { gsap } from "gsap";

import {
	HomePageSectionOne,
	HomePageSectionTwo,
	HomePageSectionThree,
	HomePageSectionFour,
	HomePageSectionFive
} from 'components/HomePageSections';
import { SchemaComponent } from 'components/ShemaComponent';

import { JsonDataContext } from 'context/jsonData';
import { AnimationContext } from 'context/animation';
import { debounce, getPageById } from 'utils';
import { initTimeLines } from './homePageTimeLines';

import APP_DATA from 'utils/jsonAppData';

const HomePage = () => {
	const { pagesData } = useContext(JsonDataContext);
	const { isHeroAnimDone, isPopupsOpen, setPopupsOpen, logoClicked } = useContext(AnimationContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);
	const [allAnimTimelines, setAllAnimTimelines] = useState(null);

	const SLIDER_AUTOPLAY_SPEED = 3000;
	const popupRefState = React.useRef(isPopupsOpen);
	const isAnimActive = useRef(false);
	const sliderInterval = useRef();
	const currentActiveSectionIndex = useRef(0);
	const nextActiveSectionIndex = useRef(1);
	const timelinesLength = useRef(1);
	const isTouch = useRef(false);
	const startTouchY = useRef(0)
	const yTouchDistance = useRef(50);

	const [activeId, secActiveId] = useState(0);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 7);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}

	};

	const handleSliderAutoPlay = () => {
		sliderInterval.current = setTimeout(() => {
			if (!isAnimActive.current && nextActiveSectionIndex.current + 1 < timelinesLength.current && !popupRefState.current) {
				forwardsAnim();
			}

		}, SLIDER_AUTOPLAY_SPEED);
	};

	const onAnimationComplete = () => {
		isAnimActive.current = false;

		handleSliderAutoPlay();
	};

	const setAnimActiveFlag = () => {
		isAnimActive.current = true;
		clearTimeout(sliderInterval.current);
	};

	const forwardsAnim = () => {
		setAnimActiveFlag();

		let animTl = gsap.timeline({
			paused: true,
			onComplete: onAnimationComplete
		});

		nextActiveSectionIndex.current = currentActiveSectionIndex.current + 1;

		animTl
			.addLabel('index_start')
			.add(allAnimTimelines[nextActiveSectionIndex.current].tl.tweenFromTo('entry_start', 'entry_end'), 'index_start')
			.add(allAnimTimelines[currentActiveSectionIndex.current].tl.tweenFromTo('leave_start', 'leave_end'), 'index_start')

		animTl.play();

		currentActiveSectionIndex.current = nextActiveSectionIndex.current;
		secActiveId(nextActiveSectionIndex.current);
	};

	const backwardsAnim = () => {
		setAnimActiveFlag();

		let animTl = gsap.timeline({
			paused: true,
			onComplete: onAnimationComplete
		});

		nextActiveSectionIndex.current = currentActiveSectionIndex.current - 1;

		animTl
			.addLabel('index_start')
			.add(allAnimTimelines[currentActiveSectionIndex.current].tl.tweenFromTo('entry_end', 'entry_start'), 'index_start')
			.add(allAnimTimelines[nextActiveSectionIndex.current].tl.tweenFromTo('leave_end', 'leave_start'), 'index_start')

		animTl.play().timeScale(3);

		currentActiveSectionIndex.current = nextActiveSectionIndex.current;

		if (nextActiveSectionIndex.current < 0) {
			currentActiveSectionIndex.current = 0;
			nextActiveSectionIndex.current = 1;
		}

		secActiveId(nextActiveSectionIndex.current);
	};

	const moveToSection = (sectionIndex, callback) => {
		if (!isAnimActive.current) {
			setAnimActiveFlag();
	
			let animTl = gsap.timeline({
				paused: true,
				onComplete: () => {
					allAnimTimelines.forEach(({
						tl,
					}, index) => {
						if (index !== sectionIndex) {
							tl.seek('entry_start');
						}
					});

					onAnimationComplete();

					if (typeof callback === 'function') {
						callback();
					}
				}
			});

			allAnimTimelines[currentActiveSectionIndex.current].tl.seek('leave_start');

			animTl
				.addLabel('index_start')
				.add(allAnimTimelines[sectionIndex].tl.tweenFromTo('entry_start', 'entry_end'), 'index_start')
				.add(allAnimTimelines[currentActiveSectionIndex.current].tl.tweenFromTo('leave_start', 'leave_end'), 'index_start')

			currentActiveSectionIndex.current = sectionIndex;
			nextActiveSectionIndex.current = sectionIndex + 1;
			secActiveId(sectionIndex);
		
			animTl.play();
		}
	};

	const moveToNextSection = () => {
		if (!isAnimActive.current && nextActiveSectionIndex.current + 1 < timelinesLength.current && !popupRefState.current) {
			forwardsAnim();
		}
	};

	const handleMouseWheel = (e) => {
		if (!isAnimActive.current && isHeroAnimDone && !popupRefState.current) {
			let upDirection = e.deltaY < 0;

			if (upDirection && currentActiveSectionIndex.current > 0) {
				backwardsAnim();
			} else if (!upDirection && nextActiveSectionIndex.current + 1 < timelinesLength.current) {
				forwardsAnim();
			}

		}
	};

	const touchMove = (e) => {
		if (isTouch.current && !isAnimActive.current && isHeroAnimDone && !popupRefState.current) {
			let currentTouchY = e.touches[0].clientY;

			if (currentTouchY < startTouchY.current - yTouchDistance.current && nextActiveSectionIndex.current + 1 < timelinesLength.current) {
				forwardsAnim();
			} else if (currentTouchY > yTouchDistance.current + startTouchY.current && currentActiveSectionIndex.current > 0) {
				backwardsAnim();
			}

		}
	}

	const handleTouchStart = (e) => {
		isTouch.current = true;
		startTouchY.current = e.touches[0].clientY;
	};

	const handleTouchMove = (e) => {
		touchMove(e);
	};

	const handleTouchEnd = () => {
		isTouch.current = false;
		startTouchY.current = 0;
	};

	useEffect(() => {
		if (!isPopupsOpen && popupRefState.current) {
			handleSliderAutoPlay();
		}

		if (isPopupsOpen && !popupRefState.current) {
			clearTimeout(sliderInterval.current);
		}

		popupRefState.current = isPopupsOpen;
	}, [isPopupsOpen]);

	useEffect(() => {
		if (isHeroAnimDone && allAnimTimelines) {
			window.addEventListener('wheel', debounce(handleMouseWheel, 35, true));
			window.addEventListener("touchstart", handleTouchStart, false);
			window.addEventListener("touchmove", handleTouchMove, false);
			window.addEventListener("touchend", handleTouchEnd, false);

			handleSliderAutoPlay();
			setPopupsOpen(false);
		}

		return () => {
			clearTimeout(sliderInterval.current);
			window.removeEventListener('wheel', debounce(handleMouseWheel, 35, true));
			window.removeEventListener("touchstart", handleTouchStart, false);
			window.removeEventListener("touchmove", handleTouchMove, false);
			window.removeEventListener("touchend", handleTouchEnd, false);
		};

	}, [allAnimTimelines, isHeroAnimDone]);

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	useEffect(() => {
		if (currentPageData) {
			let initAllTimeLines = initTimeLines();

			initAllTimeLines[0].tl.seek('entry_end');
			timelinesLength.current = initAllTimeLines.length;

			setAllAnimTimelines(initAllTimeLines);
		}

	}, [currentPageData]);

	useEffect(() => {
		if (isHeroAnimDone && currentActiveSectionIndex.current !== 0) {
			moveToSection(0);
		}
	}, [logoClicked]);

	return (
		currentPageData && (
			<>
			<SchemaComponent schemaData={APP_DATA.schemaData} />
			<div className="home_sections">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<HomePageSectionOne
					sectionData={currentPageData.section_1}
					activeId={activeId}
					timelinesLength={allAnimTimelines ? allAnimTimelines.length-1 : 1}
					moveToNextSection={moveToNextSection}
				/>
				<HomePageSectionTwo sectionData={currentPageData.section_2} />
				<HomePageSectionThree sectionData={currentPageData.section_3} />
				<HomePageSectionFour sectionData={currentPageData.section_4} />
				<HomePageSectionFive sectionData={currentPageData.section_5} activeId={activeId} />
			</div>
			</>
		)
	);

};

export default HomePage;