import React, { useRef, useState, useEffect, useContext } from 'react';

import { gsap } from "gsap";

import { JsonDataContext } from 'context/jsonData';
import MenuTrigger from './MenuTrigger';
import MenuList from '../MainMenu/MenuList';
import { SocialList } from 'components/SocialList';
import { AnimationContext } from 'context/animation';
import { TABLET_MEDIA_POINT } from 'utils/constant';
import { useLocation, useHistory } from 'react-router-dom';

import Logo from 'i/logo.svg';

const Header = () => {
	const { isHeaderOverlay, pageScrollTop, navMenuData, headerOverlayMod	} = useContext(JsonDataContext);
	const { windowWidth, toggleMainMenuState, mainMenuOpen, showPopupByKey, setLogoClicked } = useContext(AnimationContext);
	const [tlHeader] = useState(gsap.timeline({
		delay: 1
	}));

	const headerEl = useRef(null);
	const isScrollMod = useRef(false);
	const logoEl = useRef(null);
	const joinBtnEl = useRef(null);
	const location = useLocation();
	const history = useHistory();

	const handleOpenPopup = e => {
		e.preventDefault();
		showPopupByKey('join');
	};

	const logoOnClick = e => {
		e.preventDefault();

		if (location.pathname === '/') {
			setLogoClicked(prevState => !prevState);
		} else {
			history.push('/');
		}
	};

	useEffect(() => {
		if (logoEl) {
			tlHeader
				.addLabel('anim')
				.from(logoEl.current, {
					opacity: 0,
					x: -50,
					ease: "power1.out",
					duration: 1.4,
				})
		
			if (windowWidth >= TABLET_MEDIA_POINT) {

				let $mainMenuLinkDecor = document.querySelector('.main_menu_item.active_mod .main_menu_link_decor');

				tlHeader
					.from('.main_menu_link', {
						xPercent: -100,
						duration: 1.4,
						ease: "power1.out"
					}, 'anim-=.5')
					.from(joinBtnEl.current, {
						yPercent: 100,
						duration: .5,
						ease: "power2.out"
					}, 'anim+=.9')

					if ($mainMenuLinkDecor) {
						tlHeader
							.fromTo($mainMenuLinkDecor, {
								yPercent: -100,
								opacity: 0,
							}, {
								yPercent: 0,
								duration: 1.2,
								opacity: 1,
								ease: 'none',
								onComplete: () => {
									gsap.set($mainMenuLinkDecor, {clearProps: 'all'})
								}
							}, 'anim+=1.2')
					}
					
			}

		}
	}, []);

	const scrollHeaderFunc = () => {

		if (pageScrollTop > 10 && !isScrollMod.current) {
			headerEl.current.classList.add(headerOverlayMod);
			isScrollMod.current = true;
		} else {
			if (pageScrollTop <= 10 && isScrollMod.current) {
				headerEl.current.classList.remove(headerOverlayMod);
				isScrollMod.current = false;
			}
		}
	}

	useEffect(()=> {
		if (isHeaderOverlay) {
			scrollHeaderFunc();
		}
	}, [pageScrollTop, location]);

	useEffect(()=> {
		if (headerOverlayMod) {
			headerEl.current.classList.remove(headerOverlayMod);
			isScrollMod.current = false;
		}
	}, [location]);


	// get scrollWidth for header background
	function getScrollbarWidth() {
		let root = document.documentElement;
		const outer = document.createElement('div');
		outer.style.visibility = 'hidden';
		outer.style.overflow = 'scroll';
		outer.style.msOverflowStyle = 'scrollbar';
		document.body.appendChild(outer);
		const inner = document.createElement('div');
		outer.appendChild(inner);
		const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
		outer.parentNode.removeChild(outer);

		root.style.setProperty('--scroll-size', scrollbarWidth + 'px');
	}

	useEffect(() => {
		if (windowWidth >= TABLET_MEDIA_POINT) {
			getScrollbarWidth();
		}
	}, []);

	return (
		<>
			<header className="header" ref={headerEl}>
				<div className="section_in header_mod row">
					<div className="col col_1">
						<a className="logo" href="/" ref={logoEl} onClick={logoOnClick}>
							<img className="logo_img" src={Logo} alt="logo" />
						</a>
					</div>
					<div className="col col_6 no_offset_in_mod">
						<MenuTrigger handleClick={toggleMainMenuState} />
						<div className={`nav_wrap ${mainMenuOpen ? 'active_mod' : ''}`}>
							{navMenuData && (
								<MenuList />
							)}
							<div className="header_links">
								<div className="header_links_in">
									<a className="header_link joinBtn" href="#" onClick={handleOpenPopup}>
										<div className="header_link_in" ref={joinBtnEl}>SIGN UP FOR NEWS</div>
									</a>
								</div>
								<div className="header_social">
									<SocialList />
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;