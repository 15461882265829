import React from 'react';
import { Link } from 'react-router-dom';
import { getPathNameFromUrl } from 'utils';

const SearchResultItem = ({
	id,
	url,
	subtype,
	title,
	excerpt,
}) => {
	const createPageUrlByType = () => {
		const preparedSubType = subtype.toLowerCase();

		if (preparedSubType === 'post') {
			const pathName = getPathNameFromUrl(url);

			return `/news${pathName}`;
		}

		if (preparedSubType === 'page') {
			let pathName = getPathNameFromUrl(url);
			pathName = pathName.substring(0, pathName.length-1);

			return pathName;
		}

		return '/';
	};

	return (
		<Link className="search_result" to={createPageUrlByType()} target="_blank">
			{title && (
				<div className="search_result_title" dangerouslySetInnerHTML={{ __html: title }} />
			)}
			{excerpt && excerpt !== '' && (
				<div className="search_result_text" dangerouslySetInnerHTML={{ __html: excerpt }} />
			)}
			<span className="search_result_link" href="#">More</span>
		</Link>
	);
};

export default SearchResultItem;
