import React from 'react';
import Scrollbar from 'react-scrollbars-custom';

const MapCountryList = ({
	headingTitle,
	headingPlaceholder,
	countryList,
	selectedCountry,
	setSelectedCountry,
}) => {

	const filterCountryList = countryId => {
		if (countryId) {
			const newSelectedCountry = countryList.filter(({
				id,
			}) => {
				if (id === countryId) return true;
			});

			setSelectedCountry(...newSelectedCountry);
		}
	};

	return (
		<div className="map_location_col">
			<div className="map_location_title">{headingTitle}</div>
			<div className="map_location_placeholder">{headingPlaceholder}</div>
			{countryList && (
				<div className="map_location_list lg_mod">
					<Scrollbar>
						{countryList.map(({
							id,
							name,
						}, index) => {
							let isActive;

							if (selectedCountry) {
								isActive = selectedCountry.id === id ? 'active_state' : '';
							}

							return (
								<div className="map_location_item" key={index}>
									<button
										className={`map_location_button ${isActive}`}
										onClick={() => filterCountryList(id)}
									>
										{name}
									</button>
								</div>
							)
						})}
					</Scrollbar>
				</div>
			)}
		</div>
	);
};

export default MapCountryList;