export const MAP_API_KEY = 'AIzaSyBrZNofhuwJ2QPvOjleVt53ytuDRrap5KM';

export const MAP_DEFAULT_CENTER = { lat: 45.335616, lng: 9.314371 };

export const LOCATIONS = [
	{
		lat: 40, lng: 10, id: 1, text: 'its map marker #1',
	},
	{
		lat: 40, lng: 10.2, id: 2, text: 'its map marker #1111',
	},
	{
		lat: 50, lng: 5, id: 3, text: 'its map marker #2 its map marker #2',
	},
	{
		lat: 42, lng: 0, id: 4, text: 'its map marker #3',
	},
	{
		lat: 45, lng: 20, id: 5, text: 'its map marker #4',
	},
	{
		lat: 42.3, lng: 0, id: 6, text: 'its map marker #5',
	},
	{
		lat: 42.5, lng: 0, id: 7, text: 'its map marker #6',
	},
	{
		lat: 42.4, lng: 0, id: 8, text: 'its map marker #7',
	},
	{
		lat: 42.6, lng: 0, id: 9, text: 'its map marker #8',
	},
	{
		lat: 42.8, lng: 0, id: 10, text: 'hello hello hello hello hello hello hello',
	},
];

export const MAP_STYLES = [
	{
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#21232c"
			}
		]
	},
	{
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#8ec3b9"
			}
		]
	},
	{
		"elementType": "labels.text.stroke",
		"stylers": [
			{
				"color": "#1a3646"
			}
		]
	},
	{
		"featureType": "administrative.country",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "administrative.land_parcel",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "administrative.land_parcel",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#64779e"
			}
		]
	},
	{
		"featureType": "administrative.neighborhood",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "administrative.province",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "landscape.man_made",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"color": "#334e87"
			}
		]
	},
	{
		"featureType": "landscape.natural",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#174257"
			}
		]
	},
	{
		"featureType": "poi",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#283d6a"
			}
		]
	},
	{
		"featureType": "poi",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#6f9ba5"
			}
		]
	},
	{
		"featureType": "poi",
		"elementType": "labels.text.stroke",
		"stylers": [
			{
				"color": "#1d2c4d"
			}
		]
	},
	{
		"featureType": "poi.park",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#174257"
			}
		]
	},
	{
		"featureType": "poi.park",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#3C7680"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#304a7d"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#98a5be"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "labels.text.stroke",
		"stylers": [
			{
				"color": "#1d2c4d"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#2c6675"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"color": "#255763"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#b0d5ce"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "labels.text.stroke",
		"stylers": [
			{
				"color": "#174257"
			}
		]
	},
	{
		"featureType": "transit",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#98a5be"
			}
		]
	},
	{
		"featureType": "transit",
		"elementType": "labels.text.stroke",
		"stylers": [
			{
				"color": "#1d2c4d"
			}
		]
	},
	{
		"featureType": "transit.line",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#283d6a"
			}
		]
	},
	{
		"featureType": "transit.station",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#3a4762"
			}
		]
	},
	{
		"featureType": "water",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#24262e"
			}
		]
	},
	{
		"featureType": "water",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#4e6d70"
			}
		]
	}
];
