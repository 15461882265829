import React, { useEffect, useContext, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';

import { JsonDataContext } from 'context/jsonData';
import { getPageById } from 'utils';
import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';
import {BtnBase} from "components/BtnBase";

const SolutionWholesale = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 317);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	const pageContent = currentPageData && currentPageData.content;

	return (
		currentPageData && (
			<section className="section bg_v2_mod offset_bottom_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<div className="section_in">
					<SectionHead
						title={pageContent[0].title}
						titleH1
						description={pageContent[0].description}
					/>
					<div className="product_list row col col_5 offset_1 center_mod offset_mod">
						{pageContent[1].text_block.map(({ text }) => {
							return (
								<div className="product_item col col_lg_1 col_tab_1">
									{ReactHtmlParser(text)}
								</div>
							)
						})}
					</div>
					<div className="list_block_wrap row col col_5 offset_1 offset_mod">
						<div className="list_block_wrap col col_lg_3 col_tab_2 mobile_offset_mod">
							<h4 className="list_block_title">{pageContent[2].title}</h4>
							<div className="list_block">
								{ReactHtmlParser(pageContent[2].text)}
							</div>
						</div>
						<BtnBase
							centerMod
							vertCenterMod
							col
							colLg2
							colTab1
							btnSm
							buttonSettings={pageContent[3].cta_button}
						/>
					</div>
				</div>
			</section>
		)
	)
}

export default SolutionWholesale;