import React, { useRef } from "react";
import { Link } from 'react-router-dom';
import { getComingUrl } from 'utils';

const BaseLink = ({
	url,
	target,
	text,
	linkClassName
}) => {

	const comingLink = useRef(false);
	const comingLinkPath = useRef(null);

	if (url.length && url !== null) {
		const comingUrl = getComingUrl(url);

		comingLinkPath.current = comingUrl[0]
		comingLink.current = comingUrl[1];
	}

	return (
		(!comingLink.current)
			?
			<Link className={linkClassName} to={comingLinkPath.current}>{text}</Link>
			:
			<a href={comingLinkPath.current} className={linkClassName} target={target}>{text}</a>
	)
}

export default BaseLink;