import React, { useEffect, useContext, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';

import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';
import { BtnBase } from 'components/BtnBase';

import { JsonDataContext } from 'context/jsonData';
import { getPageById } from 'utils';

const Solution2 = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 178);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	const pageContent = currentPageData && currentPageData.content;

	return (
		currentPageData && (
			<section className="section bg_v2_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<div className="section_in">
					<SectionHead
						title={pageContent[0].title}
						titleH1
						description={pageContent[0].description}
					/>
					<div className="section_descr color_mod center_mod offset_mod col col_5 offset_1">
						{ReactHtmlParser(pageContent[1].text)}
					</div>
					<div className="product_list row col col_5 offset_1 center_mod offset_2_mod">
						{pageContent[2].text_block.map((item, index) => {
							return (
								<div className="product_item col col_lg_1 col_tab_1 col_mob_3" dangerouslySetInnerHTML={{ __html: item.text }} key={`product_item_${index}`}/>
							)
						})}
					</div>
					<SectionHead
						title={pageContent[3].title}
						description={pageContent[3].description}
					/>
					<div className="product_list row col col_5 offset_1 center_mod">
						{pageContent[4].text_block.map((item, index) => {
							return (
								<div className="product_item col col_lg_1 col_tab_1 col_mob_3 title_size_mod" key={`product_item_${index}`}>
									{ReactHtmlParser(item.text)}
								</div>
							)
						})}
					</div>
					{pageContent[5].cta_button && (
							<BtnBase
									buttonSettings={pageContent[5].cta_button}
									offsetMod
							/>
					)}
				</div>
			</section>
		)
	)
}

export default Solution2;