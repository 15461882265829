import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";

import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';
import { ProductsItem } from 'components/ProductsItem';

const HomePageSectionFour = ({
	sectionData
}) => {

	const {
		section_title,
		section_description,
		choose_list
	} = sectionData;

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	
	useEffect(() => {
		function handleResize() {
			setWindowWidth(window.innerWidth)
		}
		
		window.addEventListener('resize', handleResize)
	})

	const chooseSliderSettings = {
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: true,
		arrows: false,
		dots: true,
		dotsClass: 'dots_v3',
		centerMode: true,
		centerPadding: '10px 0 0',
		responsive: [
			{
				breakpoint: 541,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerPadding: '68px 0 0',
				}
			},
			{
				breakpoint: 374,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerPadding: '10px 0 0',
				}
			}
		]
	};


	return (
		<section className="section index_mod bg_mod chooseSection">
			<Decors />
			<div className="section_in">
				<SectionHead
					title={section_title}
					description={section_description}
					offsetV5Mod
				/>
				{windowWidth >= 1024 && 
					<div className="choose_list row col col_5 offset_1">
						{choose_list.map(({
							title,
							text,
							url,
						}, index) => {
							return (
								<ProductsItem
									key={index}
									title={title}
									text={text}
									url={url}
								/>
							);
						})}
					</div>
				}
				{windowWidth < 1024 && 
					<Slider {...chooseSliderSettings} className="choose_list row col col_5 offset_1">
						{choose_list.map(({
							url,
							title,
							text,
						}, index) => {
							return (
								<ProductsItem
									title={title}
									text={text}
									key={index}
									url={url}
								/>
							);
						})}
					</Slider>
				}
			</div>
		</section>

	);
};

export default HomePageSectionFour;

HomePageSectionFour.propTypes = {
	sectionData: PropTypes.object.isRequired
};