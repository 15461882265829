import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as CloseIcon } from 'i/icons/close.svg';
import { AnimationContext } from 'context/animation';
import { TABLET_MEDIA_POINT } from 'utils/constant';
import { RequestForm } from 'components/Form';
import PopupClose from './PopupClose';

const RequestPopup = ({
	isOpen,
}) => {
	const { windowWidth, showPopupByKey } = useContext(AnimationContext);

	const handleClosePopup = () => {
		showPopupByKey();
	};

	return (
		<CSSTransition
			in={isOpen}
			timeout={{
				enter: 500,
				exit: 350,
			}}
			classNames="popup"
			mountOnEnter
			unmountOnExit
		>
			<div className="popup request_mod">
				<div className="overlay" onClick={handleClosePopup} />
				<div className="popup_in request_mod">
					{windowWidth >= TABLET_MEDIA_POINT ? (
						<PopupClose onClickHandler={handleClosePopup} />
					) : null}
					<div className="section_head offset_v6_mod">
						<div className="section_w_title">
							<div className="section_title">Request Trial</div>
							{windowWidth < TABLET_MEDIA_POINT ? (
								<div className="popup_close_btn" onClick={handleClosePopup}>
									<CloseIcon className="popup_close_icon" />
								</div>
							) : null}
						</div>
						<div className="section_descr color_mod offset_l_mod">We will get back to you shortly with all the details!</div>
					</div>
					<RequestForm />
				</div>
			</div>
		</CSSTransition>
	);
};

export default RequestPopup;

// RequestPopup.propTypes = {
// 	handleOpenPopup: PropTypes.func.isRequired,
// };