import React from 'react';

const Decors = () => {
	return (
		<div className="decor">
			{new Array(6).fill('').map((item, index) => {
				return (
					<span className="decor_line" key={index} />
				)
			})}
		</div>
	);
};

export default Decors;