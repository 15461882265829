import React, { useEffect, useContext, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';

import { Decors } from 'components/Decors';
import { SectionHead } from 'components/Section';
import { BtnBase } from 'components/BtnBase';

import { JsonDataContext } from 'context/jsonData';
import { getPageById } from 'utils';

const Solution7 = () => {
	const { pagesData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 300);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.acf) {
			setCurrentPageData(preparedCurrentPageData.acf);

			if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
				setPageTitle(preparedCurrentPageData.title.rendered);
			}
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();

		}
	}, [pagesData]);

	const pageContent = currentPageData && currentPageData.content;

	return (
		currentPageData && (
			<section className="section bg_v2_mod">
				{pageTitle && (
					<Helmet>
						<title>{pageTitle}</title>
					</Helmet>
				)}
				<Decors />
				<div className="section_in">
					<SectionHead
						title={pageContent[0].title}
						titleH1
						description={pageContent[0].description}
					/>
					<div className="list_block_wrap row offset_mod">
						<div className="list_block_wrap col col_3 offset_1 col_tab_3 tab_offset_mod">
							<h4 className="list_block_title">{pageContent[1].title}</h4>
							<div className="list_block">
								{ReactHtmlParser(pageContent[1].text)}
							</div>
						</div>
						<div className="col col_3 col_tab_3">
							<div className="solution_cloud_wrap"><img className="solution_cloud_img" src={ReactHtmlParser(pageContent[2].images[0].image)} /></div>
						</div>
					</div>
					<BtnBase
						buttonSettings={pageContent[3].cta_button}
						centerMod
						btnSm
					/>
				</div>
			</section>
		)
	)
}

export default Solution7; 