import React, { useContext } from 'react';
import { Formik } from 'formik';
import { api } from 'utils/api';
import { Alert, useAlert } from 'components/Alert';
import { REQUEST_FORM_INPUTS } from './constant';
import InputForm from './InputForm';
import APP_DATA from 'utils/jsonAppData';
import { AnimationContext } from 'context/animation';

import {
	emailValidation,
	nameValidation,
} from 'utils';
import { ButtonSubmit } from 'components/Buttons';

const RequestForm = () => {
	const [alert, showAlert, hideAlert] = useAlert();
	const { showPopupByKey } = useContext(AnimationContext);

	const validate = {
		fullName: name => nameValidation("Name", name),
		email: emailValidation,
		company: () => { return null },
		interested: () => { return null },
		subjectEmail: () => { return null },
	};

	const initialValues = {
		fullName: '',
		email: '',
		company: '',
		interested: '',
		subjectEmail: APP_DATA.commonSubjectEmail,
	};

	return (
		<Formik
			initialValues={initialValues}
			mapPropsToValues={() => {
				return {
					...initialValues,
				};
			}}
			validate={values => Object.keys(values).reduce((errors, field) => {
				const error = validate[field](values[field]);
				return {
					...errors,
					...(error && { [field]: error }),
				};
			}, {})}
			onSubmit={async (values, { resetForm, setSubmitting }) => {
				let formData = new FormData();
				const hideAlertDuration = 4000;

				setSubmitting(true);

				Object.keys(values).forEach(key => {
					formData.set(key, values[key]);
				});

				formData.set('subject', values.subjectEmail);

				try {
					const sendingRes = await api.post('/contact-form-7/v1/contact-forms/848/feedback', formData, {
						headers: {
							"content-type": "multipart/form-data",
						},
					});

					if (sendingRes.data.status === 'validation_failed') {
						showAlert(sendingRes.data.message, 'danger');
						setSubmitting(false);

						setTimeout(() => {
							hideAlert();
						}, hideAlertDuration);

					} else {
						// showAlert(sendingRes.data.message, 'success');
						showPopupByKey('getInTouch');
						setSubmitting(false);
						resetForm();

						setTimeout(() => {
							hideAlert();
							showPopupByKey();
						}, hideAlertDuration);
					}

				} catch (error) {
					setSubmitting(false);
					showAlert(error, 'danger');
				}
			}}
			validateOnChange={false}
		>
			{({
				isSubmitting,
				handleSubmit,
				handleBlur,
				handleChange,
				values,
				errors,
				touched,
				setFieldValue,
			}) => (
				<form className="request" onSubmit={handleSubmit} noValidate>
					<div className="request_inner">
						{REQUEST_FORM_INPUTS.map(({
							type,
							id,
							placeholder,
							name,
							required,
							options,
						}) => {
							return (
								<InputForm
									key={id}
									type={type}
									id={id}
									placeholder={placeholder}
									name={name}
									required={required}
									options={options}
									handleChange={handleChange}
									handleBlur={handleBlur}
									values={values}
									errors={errors}
									touched={touched}
									setFieldValue={setFieldValue}
								/>
							)
						})}
					</div>
					<div className="btn_wrap">
						{alert.visible ? (
							<Alert
								alert={alert}
								hide={hideAlert}
							/>
						) : (
								<ButtonSubmit
									buttonText="Submit"
									isDisabled={isSubmitting}
								/>
							)}

					</div>
				</form>
			)}
		</Formik>
	);
};

export default RequestForm;
