import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { searchFieldValidation } from 'utils';
import { CSSTransition } from 'react-transition-group';
import { JsonDataContext } from 'context/jsonData';

import { ReactComponent as CloseIcon } from 'i/icons/close.svg';
import { AnimationContext } from 'context/animation';

const SearchPopup = ({
	isOpen
}) => {
	const { setGlobalSearchQuery } = useContext(JsonDataContext);
	const { showPopupByKey } = useContext(AnimationContext);
	const [inputValue, setInputValue] = useState('');
	const history = useHistory();

	const onChange = e => {
		setInputValue(e.target.value);
	};

	const handleClosePopup = () => {
		showPopupByKey();
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		if (searchFieldValidation(inputValue)) {
			setInputValue('');
			setGlobalSearchQuery(inputValue);
			history.push('/search');
			showPopupByKey();
		}
	};

	return (
		<CSSTransition
			in={isOpen}
			timeout={{
				enter: 500,
				exit: 350,
			}}
			classNames="popup"
			mountOnEnter
			unmountOnExit
		>
			<div className="popup v3_mod" onSubmit={onSubmit}>
				<div className="overlay" onClick={handleClosePopup} />
				<div className="popup_in">
					<div className="popup_in_content">
						<div className="popup_close icon_mod" onClick={handleClosePopup}>
							<CloseIcon className="popup_close_icon color_mod" />
						</div>
						<form onSubmit={onSubmit} noValidate>
							<div className="search_result_input v2_mod">
								<dl className="form_cell form_cell_v1_mod">
									<dt className="form_cell_title form_v1_mod hline_hide_mod">
										<label htmlFor="search_popup_input" />
									</dt>
									<dd className="form_field_wrap form_v1_mod hline_hide_mod">
										<input
											className="form_field default_mod v2_mod"
											type="text"
											id="search_popup_input"
											placeholder="Search"
											value={inputValue}
											onChange={onChange}
										/>
									</dd>
								</dl>
							</div>
						</form>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

export default SearchPopup;
