import React, {useContext} from 'react';
import { JsonDataContext } from 'context/jsonData';

import { Link, useHistory } from 'react-router-dom';

const NewsControl = () => {
	const {
		allNews,
		currentNewsId,
		setCurrentNewsId,
	} = useContext(JsonDataContext);

	const history = useHistory();

	const goNextToPage = newsIndex => {
		history.push(`/news/${allNews[newsIndex].slug}`);
	};

	const handlePrevNews = (e) => {
		let newCurrentNewsId = currentNewsId-1;

		if (currentNewsId <= 0) {
			newCurrentNewsId = 0;
		}

		setCurrentNewsId(newCurrentNewsId);
		goNextToPage(newCurrentNewsId);
	};

	const handleNextNews = () => {
		let newCurrentNewsId = currentNewsId+1;

		if (newCurrentNewsId >= allNews.length-1) {
			newCurrentNewsId = allNews.length-1;
		}

		setCurrentNewsId(newCurrentNewsId);
		goNextToPage(newCurrentNewsId);
	};

	return (
		<div className="news_menu col col_4 offset_1 no_offset_in_mod">
			<div className="news_menu_item"><button className="news_menu_link" onClick={handlePrevNews}>Previous news</button></div>
			<div className="news_menu_item"><Link className="news_menu_link" to="/news">Back to all</Link></div>
			<div className="news_menu_item"><button className="news_menu_link" onClick={handleNextNews}>Next news</button></div>
		</div>
	);
};

export default NewsControl;