export const TABLET_MEDIA_POINT = 1024;
export const  OVERLAY_PAGES = [
	{
		url: '/solutions',
		overlayMod: 'overlay_mod'
	},
	{
		url: '/services',
		overlayMod: 'overlay_2_mod'
	},
	{
		url: '/news/',
		overlayMod: 'overlay_3_mod'
	}
]