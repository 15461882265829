import React, { useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { AnimationContext } from 'context/animation';
import { Link } from 'react-router-dom';
import { getComingUrl } from 'utils';

const BtnBase = ({
	// url,
	// target,
	// text,
	offsetMod,
	offsetV2Mod,
	centerMod,
	vertCenterMod,
	col,
	colLg2,
	colTab1,
	btnSm,
	buttonSettings
}) => {

	const { button_text, button_type, email, form, link } = buttonSettings;

	const btnWrapClasses = classNames('btn_wrap', {
		offset_mod: offsetMod,
		offset_v2_mod: offsetV2Mod,
		center_mod: centerMod,
		vert_center_mod: vertCenterMod,
		col: col,
		col_lg_2: colLg2,
		col_tab_1: colTab1
	});

	const btnClasses = classNames({
		btn_sm: btnSm,
		btn_base: !btnSm
	});

	const { showPopupByKey } = useContext(AnimationContext);

	const handleOpenPopupCustomerSupport = () => {
		showPopupByKey('customerSupport');
	};

	const handleOpenPopupSalesInquiry = () => {
		showPopupByKey('salesInquiry');
	};

	const handleOpenPopupRequest = () => {
		showPopupByKey('request');
	};

	const handleOpenPopupJoin = () => {
		showPopupByKey('join');
	};

	let comingBtnLink = useRef(true);
	let comingBtnLinkPath = useRef(null);

	const commingUrlFunc = () => {

		if (link !== null && link.url) {

			const comingBtnUrl = getComingUrl(link.url);

			comingBtnLinkPath.current = comingBtnUrl[0]
			comingBtnLink.current = comingBtnUrl[1];
		}
	}

	useEffect(() => {
		if (buttonSettings) {
			commingUrlFunc();
		}
	}, [buttonSettings]);

	return (

		(buttonSettings)
			?
			<div className={btnWrapClasses}>
				{button_type === 'link' && comingBtnLink.current && link !== null && link.url && (
					<a className={btnClasses} href={link.url} target={link.url.target}>
						<span className="btn_in">{button_text}</span>
					</a>
				)}
				{button_type === 'link' && !comingBtnLink.current && (
					<Link className={btnClasses} to={comingBtnLinkPath.current}>
						<span className="btn_in">{button_text}</span>
					</Link>
				)}
				{button_type === 'email' && link.url && (
					<a className={btnClasses} href={`mailto:${email}`} target={link.url.target}>
						<span className="btn_in">{button_text}</span>
					</a>
				)}
				{button_type === 'form' && form === 'customerSupport' && (
					<button
						className="btn_base"
						onClick={handleOpenPopupCustomerSupport}
						type="button">
						<span className="btn_in">{button_text}</span>
					</button>
				)}
				{button_type === 'form' && form === 'salesInquiry' && (
					<button
						className="btn_base"
						onClick={handleOpenPopupSalesInquiry}
						type="button">
						<span className="btn_in">{button_text}</span>
					</button>
				)}
				{button_type === 'form' && form === 'request' && (
					<button
						className="btn_base"
						onClick={handleOpenPopupRequest}
						type="button">
						<span className="btn_in">{button_text}</span>
					</button>
				)}
				{button_type === 'form' && form === 'join' && (
					<button
						className="btn_base"
						onClick={handleOpenPopupJoin}
						type="button">
						<span className="btn_in">{button_text}</span>
					</button>
				)}
			</div>
			:
			<div className={btnWrapClasses}>
				{comingBtnLink.current && link.url && (
					<a className={btnClasses} href={link.url} target={link.url.target}>
						<span className="btn_in">{button_text}</span>
					</a>
				)}
				{!comingBtnLink.current && (
					<Link className={btnClasses} to={comingBtnLinkPath.current}>
						<span className="btn_in">{button_text}</span>
					</Link>
				)}
			</div>
	);
};

export default BtnBase;