import React from 'react';
import PropTypes from 'prop-types';

// import BgVideo from 'i/video_bg.mp4';

const SectionBg = ({
	url,
	alt,
	video,
	mod
}) => {
	return (
		<div className="section_bg">
			<div className={`section_bg_in ${mod || ''}`}>
				<img className="section_bg_img" src={url} alt={alt} />
			</div>
			{/* {!video && (
				<img className="section_bg_img" src={url} alt={alt} />
			)} */}
			{/* {video && (
				<video playsinline className="section_bg_img" muted loop autoPlay>
					<source src={BgVideo} type="video/mp4"  />
				</video>
			)} */}
		</div>
	);
};

export default SectionBg;

SectionBg.propTypes = {
	url: PropTypes.string.isRequired,
	alt: PropTypes.string,
};

SectionBg.defaultProps = {
	alt: 'bg image',
};