import React from 'react';
import { GlobalServiceIn } from 'components/GlobalServiceIn';

const TradingVenues = () => {
	return (
		<GlobalServiceIn 
			currentTab={2}
		/>
	)
}

export default TradingVenues;
