const APP_DATA = {
	eventsPage: {
		title: "Events"
	},
	newsPage: {
		title: 'News',
	},
	searchPage: {
		title: 'Search',
	},
	thanksGettingTouchPopup: {
		message: 'THANKS FOR GETTING<br> IN TOUCH!<br> WE WILL GET BACK<br> TO YOU SHORTLY.',
	},
	thanksSigningUpPopup: {
		message: 'THANK YOU<br> FOR SIGNING UP! STAY TUNED.',
	},
	signUpNewsForm: {
		subjectEmail: 'Feedback from avelacom.com – News',
	},
	customerSupportForm: {
		subjectEmail: 'Feedback from avelacom.com – NOC',
	},
	commonSubjectEmail: 'Feedback from avelacom.com – inquiry',
	commonEmailSubscribeText: 'A newsletter subscriber',
	schemaData: {
		"@context": "http://schema.org/",
		"@graph": [
			{
				"@type": "Organization",
				"address": {
					"@type": "PostalAddress",
					"addressLocality": "USA",
					"streetAddress": "223 South West St Suite 900 Raleigh, NC 27603"
				},
				"email": "sales@avelacom.com",
				"name": "avelacom.com",
				"telephone": "+1 919 803 0121"
			},
			{
				"@type": "Organization",
				"address": {
					"@type": "PostalAddress",
					"addressLocality": "UK",
					"streetAddress": "64 Southwark Bridge Rd, London SE1 0AS"
				},
				"email": "sales@avelacom.com",
				"name": "avelacom.com",
				"telephone": "+44 203 885 3500"
			},
			{
				"@type": "Organization",
				"address": {
					"@type": "PostalAddress",
					"addressLocality": "Singapore",
					"streetAddress": "160 Robinson Road, #24-09, S (068914)"
				},
				"email": "sales@avelacom.com",
				"name": "avelacom.com",
				"telephone": "+65 31591198"
			},
			{
				"@type": "Organization",
				"address": {
					"@type": "PostalAddress",
					"addressLocality": "Brazil",
					"streetAddress": "Rua Florida 1901, suite 221-CG Cidade Moncoes, Sao Paulo - SP 04565-001"
				},
				"email": "sales@avelacom.com",
				"name": "avelacom.com",
				"telephone": "+55 11 99838 5371"
			}
		]
	}


};

export default APP_DATA;
