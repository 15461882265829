import React from 'react';
import PropTypes from 'prop-types';

const AdvantageItem = ({
	icon,
	title,
	amount,
	text,
	preparedClassName,
}) => {
	return (
		<div className={preparedClassName}>
			<div className="advantages_img">
				<img className="advantages_img_in" src={icon} alt={title} />
			</div>
			<div className="advantages_text title_mod" dangerouslySetInnerHTML={{ __html: title }} />
			<div className="advantages_text number_mod">{amount}</div>
			<div className="advantages_text descr_mod">{text}</div>
		</div>
	);
};

export default AdvantageItem;

AdvantageItem.propTypes = {
	icon: PropTypes.string,
	title: PropTypes.string,
	amount: PropTypes.string,
	text: PropTypes.string,
	preparedClassName: PropTypes.string,
};